import React, { useState } from 'react';
import { TableFilterContainer } from 'nx-design/Table';
import { Calendar } from 'nx-design/Calendar';

type TableDateFilterProps = {
  onCancel: () => void;
  onConfirm: (value: any) => void;
};

export const TimeDateFilter = ({ onCancel, onConfirm }: TableDateFilterProps) => {
  const [startRange, setStartRange] = useState<Date>();
  const [endRange, setEndRange] = useState<Date>();

  return (
    // @ts-ignore
    <TableFilterContainer onCancel={onCancel} onConfirm={() => onConfirm({ min: startRange, max: endRange })}>
      <div style={{ display: 'flex' }}>
        <Calendar type='time' value={startRange} onChange={({ value }) => setStartRange(value)} />
        <Calendar type='time' value={endRange} onChange={({ value }) => setEndRange(value)} />
      </div>
    </TableFilterContainer>
  );
};

import React, { useState, useEffect } from 'react';
import { Radio } from 'nx-design/Radio';
import { Typography } from 'nx-design/Typography';
import { Select } from 'nx-design/Select';
import { StyledCollapse, StyledCard, StyledCardItem } from '../style';
import { CronFieldsProps, Item } from '../../../types/types';
import { optionsWeekDays, optionsWeekDaysStart } from '../../../constants/constants';

export const WeekDays = ({ value, setValue }: CronFieldsProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isRadio, setIsRadio] = useState('isEveryDayOfWeek');
  const [everyWeekDay, setEveryWeekDay] = useState<Item | null>(optionsWeekDays[0]);
  const [weekDayStart, setWeekDayStart] = useState<Item | null>(optionsWeekDaysStart[0]);
  const [lastWeekDay, setLastWeekDay] = useState<Item | null>(optionsWeekDaysStart[0]);
  const [definiteDay, setDefiniteDay] = useState<Item | null>(optionsWeekDays[0]);
  const [definiteWeek, setDefiniteWeek] = useState<Item | null>(optionsWeekDaysStart[0]);

  useEffect(() => {
    switch (isRadio) {
      case 'isEveryDayOfWeek':
        setValue({ type: isRadio, value: [] });
        break;
      case 'isEveryDefiniteDays':
        setValue({ type: isRadio, value: [everyWeekDay?.id!, weekDayStart?.id!] });
        break;
      case 'isLastDayOfMonth':
        setValue({ type: isRadio, value: [lastWeekDay?.id!] });
        break;
      case 'isDefiniteDaysOfMonth':
        setValue({ type: isRadio, value: [definiteDay?.id!, definiteWeek?.id!] });
        break;
      case 'isPeriod':
        break;
    }
  }, [isRadio, everyWeekDay, weekDayStart, lastWeekDay, definiteDay, definiteWeek, setValue]);

  return (
    <StyledCollapse label='Дни недели' iconPosition='right' isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
      {/* @ts-ignore */}
      <StyledCard horizontalSpace='m' verticalSpace='m'>
        <StyledCardItem>
          <Radio
            label='Каждый день недели'
            checked={isRadio === 'isEveryDayOfWeek'}
            onChange={() => setIsRadio('isEveryDayOfWeek')}
          />
        </StyledCardItem>
        <StyledCardItem>
          <Radio
            label='Каждый'
            checked={isRadio === 'isEveryDefiniteDays'}
            onChange={() => setIsRadio('isEveryDefiniteDays')}
          />
          <Select
            items={optionsWeekDays}
            value={everyWeekDay}
            onChange={({ value }) => setEveryWeekDay(value)}
            style={{ width: 80 }}
          />
          <Typography size='m'>день, начиная с</Typography>
          <Select
            items={optionsWeekDaysStart}
            value={weekDayStart}
            onChange={({ value }) => setWeekDayStart(value)}
            style={{ width: 80 }}
          />
        </StyledCardItem>
        <StyledCardItem column start>
          <Radio
            label='Каждый конкретный день недели/Период'
            checked={isRadio === 'isPeriod'}
            onChange={() => setIsRadio('isPeriod')}
            style={{ marginBottom: '8px' }}
          />
          {/* <Select placeholder='Выберите' items={minutes} value={value} onChange={({ value }) => setValue(value)} /> */}
        </StyledCardItem>
        <StyledCardItem>
          <Radio
            label='В'
            checked={isRadio === 'isDefiniteDaysOfMonth'}
            onChange={() => setIsRadio('isDefiniteDaysOfMonth')}
          />
          <Select
            items={optionsWeekDays}
            value={definiteDay}
            onChange={({ value }) => setDefiniteDay(value)}
            style={{ width: 80 }}
          />
          <Select
            items={optionsWeekDaysStart}
            value={definiteWeek}
            onChange={({ value }) => setDefiniteWeek(value)}
            style={{ width: 80 }}
          />
          <Typography size='m'>месяца</Typography>
        </StyledCardItem>
        <StyledCardItem>
          <Radio
            label='В последний'
            checked={isRadio === 'isLastDayOfMonth'}
            onChange={() => setIsRadio('isLastDayOfMonth')}
          />
          <Select
            items={optionsWeekDaysStart}
            value={lastWeekDay}
            onChange={({ value }) => setLastWeekDay(value)}
            style={{ width: 80 }}
          />
          <Typography size='m'>месяца</Typography>
        </StyledCardItem>
      </StyledCard>
    </StyledCollapse>
  );
};

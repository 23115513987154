import React from 'react';
import { Select } from 'nx-design/Select';
import { Typography } from 'nx-design/Typography';
import { ScalingTable } from '../ScalingTable/ScalingTable';
import { StyledDivider, StyledFunctionBar, StyledHorizontalDivider, StyledChartsWrapper } from '../../styles/common';
import { formatDateStr } from '../../helpers/utils';

type ConfigurationParametersProps = {
  fileNameList: string[];
  fileNameValue: string | null;
  configFileData: any;
  setFileNameValue: (value: string | null) => void;
  handleChange: (event: any) => void;
};

export const ConfigurationParameters = ({
  fileNameList,
  fileNameValue,
  configFileData,
  setFileNameValue,
  handleChange,
}: ConfigurationParametersProps) => {
  const changeDate = configFileData.createDate ? formatDateStr(configFileData.createDate) : '';

  return (
    <>
      <StyledFunctionBar>
        <Typography size='m' style={{ marginRight: 16 }}>
          Конфигурационый файл
        </Typography>
        <Select
          size='s'
          items={fileNameList || []}
          value={fileNameValue || fileNameList[0]}
          onChange={({ value }) => setFileNameValue(value)}
          style={{ width: 310, marginRight: 20 }}
          getItemLabel={(item) => item}
          getItemKey={(item) => item}
        />
        <StyledDivider style={{ marginRight: 22 }} />
        <Typography size='m' style={{ marginRight: 16 }}>
          Дата изменения
        </Typography>
        <Typography size='m' weight='medium' style={{ marginRight: 20, color: '#404040' }}>
          {changeDate}
        </Typography>
        <StyledDivider style={{ marginRight: 22 }} />
        <Typography size='m' style={{ marginRight: 16 }}>
          Автор изменения
        </Typography>
        <Typography size='m' weight='medium' style={{ marginRight: 16, color: '#404040' }}>
          {configFileData.createUser}
        </Typography>
      </StyledFunctionBar>
      <StyledChartsWrapper>
        <ScalingTable mode='verticalScaling' data={configFileData} handleChange={handleChange} />
        <ScalingTable mode='horizontalScaling' data={configFileData} handleChange={handleChange} />
      </StyledChartsWrapper>
      <StyledHorizontalDivider />
    </>
  );
};

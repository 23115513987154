import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
// eslint-disable-next-line import/no-unresolved
import { Line } from 'react-chartjs-2';
import { StyledChartBlockWrapper, StyledChartWrapper, StyledChartTitle, StyledDateWrapper } from './style';
import { Typography } from 'nx-design/Typography';
import { DatePicker } from 'nx-design/DatePicker';
import { PodContainersValues } from '../../types/types';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom' as const,
      align: 'start' as const,
      labels: {
        usePointStyle: true,
        color: 'rgb(102 102 102)',
        pointStyle: 'circle',
      },
    },
    tooltip: {
      usePointStyle: true,
      pointStyle: 'circle',
    },
  },
};

type ChartProps = {
  title: string;
  data: PodContainersValues[];
  cpuRange?: any;
  memoryRange?: any;
  setCpuRange?: any;
  setMemoryRange?: any;
};

export const Chart = ({ title, data, cpuRange, memoryRange, setCpuRange, setMemoryRange }: ChartProps) => {
  const dataChart = {
    labels: data[0]?.values.map((item) => item.time) || [],
    datasets: data.length
      ? data?.map((item) => ({
          fill: true,
          label: item.label,
          data: item.values.map((item) => item.volume),
          borderColor: '#' + (((1 << 24) * Math.random()) | 0).toString(16).padStart(6, '0'),
          tension: 0.4,
        }))
      : [
          {
            label: '',
            data: [],
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1,
          },
        ],
  };

  return (
    <StyledChartBlockWrapper>
      <StyledChartTitle>
        <Typography size='l' weight='bold' style={{ color: '#404040' }}>
          {title}
        </Typography>
      </StyledChartTitle>
      <StyledChartWrapper>
        <StyledDateWrapper>
          <DatePicker
            type='date-time'
            formatMask='MM.dd.yyyy HH:mm:ss'
            value={cpuRange ? cpuRange.startRange : memoryRange.startRange}
            onChange={({ value }) =>
              cpuRange
                ? setCpuRange((prevState: any) => ({ ...prevState, startRange: value }))
                : setMemoryRange((prevState: any) => ({ ...prevState, startRange: value }))
            }
            style={{ marginRight: 3 }}
          />
          <DatePicker
            type='date-time'
            formatMask='MM.dd.yyyy HH:mm:ss'
            value={cpuRange ? cpuRange.endRange : memoryRange.endRange}
            onChange={({ value }) =>
              cpuRange
                ? setCpuRange((prevState: any) => ({ ...prevState, endRange: value }))
                : setMemoryRange((prevState: any) => ({ ...prevState, endRange: value }))
            }
          />
        </StyledDateWrapper>
        <Line options={options} data={dataChart} />
      </StyledChartWrapper>
    </StyledChartBlockWrapper>
  );
};

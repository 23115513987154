export const ROUTES = {
  home: '/',
  auth: '/auth',
  executionHistory: '/execution-history',
  settingsSchedule: '/settings-schedule',
  airflow: '/airflow',
  sparkHistoryServer: '/spark-history-server',
  livy: '/livy',
  securityAudit: '/security-audit',
  logging: '/logging',
  configuringEnvironment: '/configuring-environment',
  componentView: '/configuring-environment/:name',
  notificationSettings: '/notification-settings',
};

export const CALENDAR_DATE_WITH_DOT_SEPARATOR = 'yyyy.MM.dd HH:mm:ss';

export const optionsEveryMinutes = [...Array(59)].map((_, index) => ({
  label: `${index + 1}ю`,
  id: index + 1,
}));

export const optionsMinutesStart = [...Array(60)].map((_, index) => ({
  label: `${index}`,
  id: index,
}));

export const optionsEveryMonths = [...Array(12)].map((_, index) => ({
  label: `${index + 1}й`,
  id: index + 1,
}));

export const optionsMonthsStart = [
  { label: 'Январь', id: 1 },
  { label: 'Февраль', id: 2 },
  { label: 'Март', id: 3 },
  { label: 'Апрель', id: 4 },
  { label: 'Май', id: 5 },
  { label: 'Июнь', id: 6 },
  { label: 'Июль', id: 7 },
  { label: 'Август', id: 8 },
  { label: 'Сентябрь', id: 9 },
  { label: 'Октябрь', id: 10 },
  { label: 'Ноябрь', id: 11 },
  { label: 'Декабрь', id: 12 },
];

export const optionsEveryHours = [...Array(23)].map((_, index) => ({
  label: `${index + 1}й`,
  id: index + 1,
}));

export const optionsHoursStart = [...Array(24)].map((_, index) => ({
  label: `${index}`,
  id: index,
}));

export const optionsEveryDays = [...Array(31)].map((_, index) => ({
  label: `${index + 1}й`,
  id: index + 1,
}));

export const optionsDayStart = [...Array(31)].map((_, index) => ({
  label: `${index + 1}го`,
  id: index + 1,
}));

export const optionsNextWeekDay = [...Array(31)].map((_, index) => ({
  label: `${index + 1}му`,
  id: index + 1,
}));

export const optionsWeekDays = [...Array(6)].map((_, index) => ({
  label: `${index + 1}й`,
  id: index + 1,
}));

export const optionsWeekDaysStart = [
  { label: 'ПН', id: 1 },
  { label: 'ВТ', id: 2 },
  { label: 'СР', id: 3 },
  { label: 'ЧТ', id: 4 },
  { label: 'ПТ', id: 5 },
  { label: 'СБ', id: 6 },
  { label: 'ВС', id: 7 },
];

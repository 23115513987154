import styled from 'styled-components';

export const StyledScalingTableWrapper = styled.div`
  background-color: white;
  box-shadow: 0px 2px 2px rgba(23, 29, 69, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  width: 100%;
`;

export const StyledTitle = styled.div`
  padding: 24px;
  border-bottom: 1px solid #e6e6e6;
`;

export const StyledHeader = styled.div`
  display: flex;
  padding: 10px 24px;
  border-bottom: 1px solid #e6e6e6;

  > div {
    width: 50%;
  }
`;

export const StyledTableContent = styled.div`
  padding: 20px 24px;
`;

export const StyledTableContentItem = styled.div`
  display: flex;
  align-items: center;
  > div {
    width: 50%;
  }
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

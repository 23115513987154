import { Button } from 'nx-design/Button';
import { TextField } from 'nx-design/TextField';
import { IconGlobe } from 'nx-design/IconGlobe';
import { Typography } from 'nx-design/Typography';

import styled from 'styled-components';

export const LoginWrapper = styled.div`
  background: #ffffff;
  padding-left: 32px;
  padding-right: 34px;
  padding-top: 40px;
  padding-bottom: 34px;
  width: 276px;
  min-height: 278px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.03), 0px 12px 36px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  margin-top: 31vh;
  margin-bottom: 31vh;
`;

export const StyledButton = styled(Button)`
  width: 80px;
`;

export const StyledIconGlobe = styled(IconGlobe)`
  margin-top: 7px;
  margin-right: -8px;
`;

export const StyledTextField = styled(TextField)`
  width: 278px;
`;

export const StyledSubmitButton = styled(Button)`
  width: 278px;
`;

export const StyledTypography = styled(Typography)`
  color: red;
  text-align: center;
`;

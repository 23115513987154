import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { DagsState } from '../types';
import { Dags } from '../../types/types';

const initialState: DagsState = {
  dags: [],
};

const dagsSlice = createSlice({
  name: 'dags',
  initialState,
  reducers: {
    setDags: (state, { payload }: PayloadAction<Dags[]>) => {
      state.dags = payload;
    },
  },
});

export const dagsReducer = dagsSlice.reducer;
export const dagsSelector = (state: RootState) => state.dags;
export const { setDags } = dagsSlice.actions;

import React from 'react';
import { Button } from 'nx-design/Button';
import {
  IconClose,
  IconSave,
  IconBlock,
  IconDelete,
  IconCheck,
  IconErase,
  IconArrowBackward,
} from '../../assets/Assets';
import {
  StyledSidebarWrapper,
  StyledHeaderTypo,
  StyledHeaderTypoContainer,
  StyledHeaderContainer,
  SidebarButton,
  StyledSidebarActions,
  StyledSidebarContent,
  StyledSidebarContentWrapper,
} from './style';

type RightSideBarProps = {
  title: string;
  isOpen: boolean;
  children: React.ReactNode;
  isCronEditor?: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSave: () => void;
  onClose: () => void;
};

export const RightSideBar = ({
  title,
  isOpen,
  children,
  isCronEditor = false,
  setIsOpen,
  onSave,
  onClose,
}: RightSideBarProps) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <StyledSidebarWrapper isOpen={isOpen} position='right' hasOverlay={false} width='auto' includeSpace>
      <StyledSidebarContentWrapper>
        <StyledHeaderContainer>
          <StyledHeaderTypoContainer>
            {isCronEditor && (
              <Button
                size='s'
                view='clear'
                iconLeft={IconArrowBackward}
                onlyIcon
                onClick={handleClose}
                style={{ marginRight: 8 }}
              />
            )}
            {/* @ts-ignore */}
            <StyledHeaderTypo size='l' view='primary' weight='medium'>
              {title}
            </StyledHeaderTypo>
            <Button view='clear' iconLeft={IconClose} onlyIcon onClick={handleClose} />
          </StyledHeaderTypoContainer>
        </StyledHeaderContainer>
        <StyledSidebarContent>{children}</StyledSidebarContent>
      </StyledSidebarContentWrapper>
      <StyledSidebarActions>
        <div>
          <SidebarButton>
            <Button
              size='m'
              label={isCronEditor ? 'Применить' : 'Сохранить'}
              width='default'
              onClick={() => {
                onSave();
              }}
              iconLeft={isCronEditor ? IconCheck : IconSave}
              iconSize='xs'
            />
          </SidebarButton>
          <SidebarButton>
            <Button
              size='m'
              view='secondary'
              label={isCronEditor ? 'Очистить' : 'Отменить'}
              width='default'
              onClick={() => {
                onClose();
                handleClose();
              }}
              iconLeft={isCronEditor ? IconErase : IconBlock}
              iconSize='xs'
            />
          </SidebarButton>
        </div>
        {!isCronEditor && <Button size='m' view='secondary' width='default' iconLeft={IconDelete} iconSize='xs' />}
      </StyledSidebarActions>
    </StyledSidebarWrapper>
  );
};

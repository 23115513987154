import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { KubernetesComponent } from '../../types/types';

const initialState = {
  kubernetesComponents: [] as KubernetesComponent[],
  isEditing: false,
};

const kubernetesComponentsSlice = createSlice({
  name: 'kubernetesComponents',
  initialState,
  reducers: {
    setKubernetesComponents: (state, { payload }: PayloadAction<KubernetesComponent[]>) => {
      state.kubernetesComponents = payload;
    },
    setIsEditing: (state, { payload }: PayloadAction<boolean>) => {
      state.isEditing = payload;
    },
  },
});

export const kubernetesComponentsReducer = kubernetesComponentsSlice.reducer;
export const kubernetesComponentsSelector = (state: RootState) => state.kubernetesComponents;
export const { setKubernetesComponents, setIsEditing } = kubernetesComponentsSlice.actions;

import { userReducer } from './slices/userSlice';
import { dagsReducer } from './slices/dagsSlice';
import { workflowDeploymentsReducer } from './slices/workflowDeploymentsSlice';
import { notificationsReducer } from './slices/notificationsSlice';
import { kubernetesComponentsReducer } from './slices/environmentComponentsSlice';
import { counterReducer } from './slices/counterSlice';

const reducer = {
  user: userReducer,
  dags: dagsReducer,
  workflowDeployments: workflowDeploymentsReducer,
  notification: notificationsReducer,
  kubernetesComponents: kubernetesComponentsReducer,
  counter: counterReducer,
};

export default reducer;

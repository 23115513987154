import styled from 'styled-components';
import { Typography } from 'nx-design/Typography';
import { Modal } from 'nx-design/Modal';

export const StyledDivider = styled.div`
  height: 16px;
  width: 1px;
  background-color: #e6e6e6;
  margin: 0 4px;
`;

export const StyledTable = styled.div`
  .TableCell_isHeader {
    text-transform: none;
  }
  .TableHeader-Row .TableCell-Wrapper {
    padding-top: 13px;
    padding-bottom: 13px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #666666;
  }

  .TableHeader-Row .TableCell:first-child,
  .Table-CellsRow .TableCell:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .TableHeader-Row .TableCell:last-child,
  .Table-CellsRow .TableCell:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .TableHeader-Row .TableCell {
    border: none;
  }

  .TableHeader-ShadowWrapper {
    margin-top: 5px;
  }

  .Table-CellsRow > div {
    margin-bottom: 5px;
  }
  .TableHeader-Buttons_verticalAlign_center {
    display: block;
  }
  .TableHeader-Icon {
    opacity: 1;
  }
  .Table-CellsRow .TableCell {
    position: relative;
    box-shadow: 0px 2px 0px rgb(23 29 69 / 2%), 3px 2px 3px rgb(0 0 0 / 4%);
  }
  .Table-CellsRow .TableCell:not(:first-child)::after {
    content: '';
    position: absolute;
    left: 0;
    top: 20%;
    z-index: 100;
    border-radius: 4px;
    width: 1px;
    height: 24px;
    background: #e6e6e6;
  }
  .TableCell-Wrapper_isSeparateRows:not(.Table-ContentCell_isFirstColumn)::after {
    display: none !important;
  }
  .Table-RowWithoutCells {
    border-radius: 4px;
    margin-bottom: 5px;
  }
  .TableSelectedOptionsList {
    border: none;
  }
`;

export const StyledContentWrapper = styled.div`
  padding: 16px;
  height: 87vh;
  background-color: #f2f2f2;
  overflow: auto;
`;

export const StyledFunctionBar = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 16px;
  background-color: white;
  box-shadow: 0px 2px 2px rgba(23, 29, 69, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  margin-bottom: 16px;
`;

export const StyledModalTitle = styled(Typography)`
  color: var(--color-base-base-grey-8);
  margin-bottom: var(--space-m);
  font-weight: 600;
`;

export const StyledModalText = styled(Typography)`
  color: var(--color-base-base-grey-9);
  font-weight: 400;
  margin-bottom: 16px;
`;

export const StyledContent = styled.div`
  padding: var(--space-m);
`;

export const StyledButtonWrapper = styled.div`
  border-top: 1px solid var(--color-base-base-grey-3);
  padding: var(--space-m);
`;

export const StyledModal = styled(Modal)`
  min-height: 154px !important;
`;

export const StyledChartsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  > div:not(:last-child) {
    margin-right: 16px;
  }
`;

export const StyledHorizontalDivider = styled.div`
  background: #e6e6e6;
  border-radius: 4px;
  height: 1px;
  margin-bottom: 16px;
`;

import styled from 'styled-components';

export const StyledChartBlockWrapper = styled.div`
  background-color: white;
  box-shadow: 0px 2px 2px rgba(23, 29, 69, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  width: 100%;
`;

export const StyledChartTitle = styled.div`
  padding: 24px;
  border-bottom: 1px solid #e6e6e6;
`;

export const StyledChartWrapper = styled.div`
  padding: 24px;
`;

export const StyledDateWrapper = styled.div`
  margin-bottom: 16px;
`;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, GridItem } from 'nx-design/Grid';
import { Login } from '../../components/Login/Login';
import { LoginState } from '../../types/types';
import styled from 'styled-components';
import { postAuth } from '../../api/api';
import { ROUTES } from '../../constants/constants';
import { isAuthorized } from '../../helpers/utils';

const AuthorizationWrapper = styled.div`
  --text-color: var(--color-typo-primary);
  color: var(--text-color);
  background: #f2f2f2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const initialLoginState: LoginState = { username: '', password: '' };

export const Auth = () => {
  const [lang, setLang] = useState('RU');
  const [logInData, setLogInData] = useState<LoginState>(initialLoginState);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handleChangeLang = () => {
    setLang(lang === 'RU' ? 'EN' : 'RU');
  };

  const handleChange = (fieldName: string, fieldValue: string | null) => {
    setLogInData({
      ...logInData,
      [fieldName]: fieldValue,
    });
    setError('');
  };

  const handleFormSubmit = async () => {
    try {
      const { data } = await postAuth(logInData);
      localStorage.setItem('token', JSON.stringify(data.token));
      localStorage.setItem('username', JSON.stringify(data.username));
      localStorage.setItem('roles', JSON.stringify(data.roles));
      navigate(ROUTES.home);
    } catch (e) {
      setError('Неправильный логин или пароль');
    }
  };

  useEffect(() => {
    if (isAuthorized()) {
      navigate(ROUTES.home);
    }
  }, []);

  return (
    <AuthorizationWrapper>
      <Grid cols='3' xAlign='center' yAlign='center'>
        <GridItem />
        <GridItem>
          <Login
            lang={lang}
            logInData={logInData}
            onSubmit={handleFormSubmit}
            onChange={handleChange}
            onChangeLang={handleChangeLang}
            error={error}
          />
        </GridItem>
        <GridItem />
      </Grid>
    </AuthorizationWrapper>
  );
};

import React from 'react';
import { Outlet } from 'react-router-dom';
import { Sidebar } from '../Sidebar/Sidebar';
import { Header } from '../Header/Header';
import { StyledContent, StyledLayoutWrapper } from './style';

export const Layout = () => {
  return (
    <StyledLayoutWrapper>
      <Sidebar />
      <StyledContent>
        <Header />
        <Outlet />
      </StyledContent>
    </StyledLayoutWrapper>
  );
};

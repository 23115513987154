import styled from 'styled-components';
import { Typography } from 'nx-design/Typography';
import { Sidebar } from 'nx-design/Sidebar';

export const StyledHeaderContainer = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
`;

export const StyledHeaderTypo = styled(Typography)`
  color: var(--color-base-base-grey-8);
  background-color: unset;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
`;

export const StyledHeaderTypoContainer = styled.div`
  background-color: white;
  display: flex;
  border-bottom: 1px solid var(--color-bg-border);
  padding: 9px 24px;
  align-items: center;
`;

export const SidebarButton = styled.div`
  display: inline;
  padding: 8px;
`;

export const StyledSidebarActions = styled(Sidebar.Actions)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  background: var(--color-bg-default) !important;
`;

export const StyledSidebarContentWrapper = styled(Sidebar.Content)``;

export const StyledSidebarContent = styled.div``;

export const StyledSidebarWrapper = styled(Sidebar)`
  margin-top: 64px;
  width: 520px !important;
  height: 94vh !important;
  background: var(--color-base-base-grey-2) !important;
  .sidebar-actions-panel {
    padding: 8px;
    height: 40px;
    border-bottom: 1px solid var(--color-bg-border);
    background-color: white;
  }

  .sidebar-action-button {
    display: inline;
  }

  .sidebar-content {
    padding: 10px 24px 0 24px;
  }

  .sidebar-content .Checkbox-Input {
    border-color: var(--color-base-base-grey-7);
  }

  .sidebar-content .Checkbox-Input:checked {
    border-color: var(--checkbox-checked-border);
  }

  .sidebar-button {
    display: inline;
    padding: 8px;
  }

  .Sidebar-Actions {
    padding: 16px;
  }

  .Sidebar-Window_position_right {
    width: 720px;
  }

  .sidebar .Button_view_primary {
    border: var(--control-border-width) solid var(--color-control-bg-border-secondary);
  }

  .sidebar .Sidebar-Content {
    background-color: var(--color-base-base-grey-2);
  }

  .sidebar-line {
    display: inline;
    margin: 8px;
  }

  .sidebar-line::before {
    content: '';
    position: absolute;
    height: 16px;
    top: 77px;
    width: 1px;
    background: var(--color-bg-border);
    opacity: 1;
    transition: opacity var(--fast-transition);
  }

  .sidebar-actions-panel .Button {
    margin-right: 2px;
  }

  .sidebar-line-second::before {
    left: 136px;
  }

  .sidebar-header-container .ContextMenuLevel {
    width: 300px;
  }
`;

import React from 'react';
// eslint-disable-next-line import/named
import { TableColumn, TableFilters, TableTextFilter } from 'nx-design/Table';
import { Counter, Dags, DagsStateEnum } from '../types/types';
import { StyledStatus } from '../containers/Home/style';
import { changeDagsStateName, formatStringTimeToDate, filtersDate, filterMultipleValues } from './utils';
import { TableDateFilter } from '../components/TableDateFilter/TableDateFilter';
import { TimeDateFilter } from '../components/TimeDateFilter/TimeDateFilter';

export const ITEMS = [
  {
    name: 'waiting',
    label: 'Ожидает',
    checked: true,
    value: 'wait' as Counter,
    color: '#D7F8FC',
    backgroundColor: '#2AA2C9',
  },
  {
    name: 'queued',
    label: 'В очереди',
    checked: true,
    value: 'queued' as Counter,
    color: '#F2F2F2',
    backgroundColor: '#B3B3B3',
  },
  {
    name: 'running',
    label: 'Выполняется',
    checked: true,
    value: 'running' as Counter,
    color: '#FFF8E0',
    backgroundColor: '#D9A54C',
  },
  {
    name: 'success',
    label: 'Успешно выполнено',
    checked: true,
    value: 'success' as Counter,
    color: '#D5F0D8',
    backgroundColor: '#0DA955',
  },
  {
    name: 'failed',
    label: 'Ошибки',
    checked: true,
    value: 'failed' as Counter,
    color: '#EDDADF',
    backgroundColor: '#BA346A',
  },
];

export const columns: TableColumn<Dags>[] = [
  {
    title: 'DAG',
    accessor: 'id',
    align: 'left',
    sortable: true,
  },
  {
    title: 'Статус',
    accessor: 'state',
    align: 'left',
    sortable: true,
    renderCell: (row) => <StyledStatus status={row.state}>{changeDagsStateName(row.state)}</StyledStatus>,
    sortFn: (a, b) => sortOrderDags.indexOf(a as DagsStateEnum) - sortOrderDags.indexOf(b as DagsStateEnum),
  },
  {
    title: 'Дата старта',
    accessor: 'startDate',
    align: 'center',
    sortable: true,
  },
  {
    title: 'Дата завер.',
    accessor: 'endDate',
    align: 'center',
    sortable: true,
  },
  {
    title: 'Время выпол.',
    accessor: 'executionTime',
    align: 'center',
    sortable: true,
  },
];

export const filters: TableFilters<Dags> = [
  {
    id: 'executor',
    name: 'Статус: ',
    field: 'state',
    filterer: filterMultipleValues,
    component: {
      name: TableTextFilter,
      props: {
        withSearch: true,
        items: [
          { name: 'Ожидает', value: DagsStateEnum.WAITING },
          { name: 'В очереди', value: DagsStateEnum.QUEUED },
          { name: 'Выполняется', value: DagsStateEnum.RUNNING },
          { name: 'Успешно выполнено', value: DagsStateEnum.SUCCESS },
          { name: 'Ошибки', value: DagsStateEnum.FAILED },
        ],
      },
    },
  },
  {
    id: 'startDate',
    name: 'Диапазон начала: ',
    field: 'startDate',
    filterer: filtersDate,
    component: {
      name: TableDateFilter,
    },
  },
  {
    id: 'endDate',
    name: 'Диапазон конца: ',
    field: 'endDate',
    filterer: filtersDate,
    component: {
      name: TableDateFilter,
    },
  },
  {
    id: 'executionTime',
    name: 'Диапазон времени выполнения: ',
    field: 'executionTime',
    filterer: (cellValue: string, filterValue: { min: Date; max: Date }) =>
      formatStringTimeToDate(cellValue) >= filterValue.min && formatStringTimeToDate(cellValue) <= filterValue.max,
    component: {
      name: TimeDateFilter,
    },
  },
];

export const sortOrderDags = [
  DagsStateEnum.FAILED,
  DagsStateEnum.WAITING,
  DagsStateEnum.QUEUED,
  DagsStateEnum.RUNNING,
  DagsStateEnum.SUCCESS,
];

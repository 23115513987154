import styled from 'styled-components';

export const StyledLayoutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f2f2f2;
`;

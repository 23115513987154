import styled from 'styled-components';
import { getStatusElementsStyle } from '../../helpers/utils';

export const StyledStatus = styled.div<{ status: string }>(
  ({ status }) => `
  ${getStatusElementsStyle(status)}
  border-radius: 4px;
  padding: 1px 8px;
`,
);

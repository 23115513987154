import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Auth } from '../containers/Auth/Auth';
import { Home } from '../containers/Home/Home';
import { SettingsSchedule } from '../containers/SettingsSchedule/SettingsSchedule';
import { Airflow } from '../containers/Airflow/Airflow';
import { SparkHistoryServer } from '../containers/SparkHistoryServer/SparkHistoryServer';
import { Livy } from '../containers/Livy/Livy';
import { Logging } from '../containers/Logging/Logging';
import { Layout } from '../components/Layout/Layout';
import { isAuthorized } from '../helpers/utils';
import { ConfiguringEnvironment } from '../containers/ConfiguringEnvironment/ConfiguringEnvironment';
import { ComponentView } from '../containers/ComponentView/ComponentView';
import { Metrics } from '../containers/Metrics/Metrics';
import { ROUTES } from '../constants/constants';

export const RoutesApp = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized()) {
      navigate(ROUTES.auth);
    }
  }, []);

  return (
    <Routes>
      <Route path={ROUTES.home} element={<Layout />}>
        <Route index element={<Home />} />
        <Route path={ROUTES.auth} element={<Auth />} />
        <Route path={ROUTES.executionHistory} element={<div>История выполнения</div>} />
        <Route path={ROUTES.settingsSchedule} element={<SettingsSchedule />} />
        <Route path={ROUTES.airflow} element={<Airflow />} />
        <Route path={ROUTES.sparkHistoryServer} element={<SparkHistoryServer />} />
        <Route path={ROUTES.livy} element={<Livy />} />
        <Route path={ROUTES.securityAudit} element={<div>Аудит безопасности</div>} />
        <Route path={ROUTES.logging} element={<Logging />} />
        <Route path={ROUTES.configuringEnvironment} element={<ConfiguringEnvironment />} />
        <Route path={ROUTES.componentView} element={<ComponentView />} />
        <Route path={ROUTES.notificationSettings} element={<Metrics />} />
      </Route>
    </Routes>
  );
};

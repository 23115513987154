import React from 'react';
import { Typography } from 'nx-design/Typography';
import { Select } from 'nx-design/Select';
import { FlowCounter } from '../../components/FlowCounter/FlowCounter';
import { Chart } from '../../components/Chart/Chart';
import { ITEMS } from '../../helpers/ComponentViewHelpers';
import { StyledFunctionBar, StyledChartsWrapper } from '../../styles/common';
import { PodContainersValues } from '../../types/types';

type PodsParametersProps = {
  podsLists: string[];
  podValue: string | null;
  cpuValues: PodContainersValues[];
  memoryValues: PodContainersValues[];
  cpuRange: any;
  memoryRange: any;
  setCpuRange: any;
  setMemoryRange: any;
  setPodValue: (value: string | null) => void;
};

export const PodsParameters = ({
  podsLists,
  podValue,
  cpuValues,
  memoryValues,
  cpuRange,
  memoryRange,
  setCpuRange,
  setMemoryRange,
  setPodValue,
}: PodsParametersProps) => {
  return (
    <>
      <StyledFunctionBar>
        <Typography size='m' style={{ marginRight: 16 }}>
          Pod
        </Typography>
        <Select
          size='s'
          items={podsLists || []}
          value={podValue || podsLists[0]}
          onChange={({ value }) => setPodValue(value)}
          style={{ width: 310 }}
          getItemLabel={(item) => item}
          getItemKey={(item) => item}
        />
      </StyledFunctionBar>
      <FlowCounter items={ITEMS} />
      <StyledChartsWrapper>
        <Chart title='CPU Usage' data={cpuValues} cpuRange={cpuRange} setCpuRange={setCpuRange} />
        <Chart title='Memory Usage' data={memoryValues} memoryRange={memoryRange} setMemoryRange={setMemoryRange} />
      </StyledChartsWrapper>
    </>
  );
};

import axios from 'axios';
import { isAuthorized } from '../helpers/utils';

const BASE_URL = 'https://ac.ru-central1.internal/api';
const token = isAuthorized();
const headers = {
  Accept: 'application/json',
  Authorization: `Bearer ${token}`,
  'Content-Type': 'application/json',
};

export const axiosFetch = axios.create({
  baseURL: `${BASE_URL}`,
  headers,
});

import styled from 'styled-components';
import { StyledTable } from '../../styles/common';

export const StyledTableSettings = styled(StyledTable)`
  margin-top: 0px;
  height: 90vh;
  .Table {
    overflow: auto;
  }
  .TableHeader-Row > div {
    top: 42px !important;
  }
  .TableHeader-ShadowWrapper {
    --table-header-height: 82px !important;
  }
  .Table > .Table-RowWithoutCells {
    z-index: 3;
    position: sticky;
    top: 0px;
  }
  .HeaderMenu {
    border: none;
  }
  .HeaderMenu .Button {
    margin-top: 8px;
    margin-bottom: 4px;
  }
  .TableHeader-Row .TableCell:first-child {
    border-radius: unset;
  }
  .Table-RowWithoutCells:has(.HeaderMenu) {
    border-radius: unset;
    margin-bottom: 0px;
    border-bottom: 1px solid #e6e6e6;
  }
  .TableHeader-ShadowWrapper {
    margin-top: 0px;
  }
  .Table-CellsRow > div:last-child {
    margin-right: 8px;
  }
  .TableHeader-Cell::after {
    display: none;
  }
  .TableHeader-Cell:not(:first-child)::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    bottom: 8px;
    width: 1px;
    background: var(--color-bg-border);
  }
`;

import styled from 'styled-components';
import { Header, HeaderModule } from 'nx-design/Header';

export const StyledHeader = styled(Header)`
  height: 64px !important;
  padding: 0px 18px;
  border-bottom: 1px solid #e6e6e6;
`;

export const StyledProfile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: #2aa2c9;
  border-radius: 4px;
  margin-right: 12px;
`;

export const StyledHeaderModule = styled(HeaderModule)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

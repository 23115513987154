import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ActionHome } from '../../components/ActionHome/ActionHome';
import { StyledContentWrapper } from '../../styles/common';
import { useSelector, useDispatch } from 'react-redux';
import { kubernetesComponentsSelector, setIsEditing } from '../../redux/slices/environmentComponentsSlice';
import { ConfigurationParameters } from '../../components/ConfigurationParameters/ConfigurationParameters';
import { PodsParameters } from '../../components/PodsParameters/PodsParameters';
import { SaveSettingsModal } from '../../components/SaveSettingsModal/SaveSettingsModal';
import {
  createConfigFile,
  getConfigFilesByComponentName,
  getContainersOnPod,
  getKubernetesComponentLists,
} from '../../api/api';
import { setCounter } from '../../redux/slices/counterSlice';
import { Loader } from '../../components/Loader/Loader';
import { PodContainersValues } from '../../types/types';
import { subDays } from 'date-fns';

type EnvironmentComponent = {
  componentName: string;
  namespace: string;
  createDate: string;
  createUser: string;
};

export const ComponentView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentComponent, setCurrentComponent] = useState<EnvironmentComponent>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [componentLists, setComponentLists] = useState<{ configurationFilenameList: string[]; pods: string[] }>({
    pods: [],
    configurationFilenameList: [],
  });
  const [podValue, setPodValue] = useState<string | null>('');
  const [fileNameValue, setFileNameValue] = useState<string | null>('');
  const [configFileData, setConfigFileData] = useState({
    configName: '',
    componentName: '',
    namespace: '',
    createDate: '',
    createUser: '',
    limitCpu: 0,
    limitMemory: 0,
    consumptionCpu: 0,
    consumptionMemory: 0,
    replicas: 0,
  });
  const [memoryValues, setMemoryValues] = useState<PodContainersValues[]>([]);
  const [cpuValues, setCpuValues] = useState<PodContainersValues[]>([]);
  const [cpuRange, setCpuRange] = useState<{ startRange: Date; endRange: Date }>({
    startRange: subDays(Date.now(), 1),
    endRange: new Date(),
  });
  const [memoryRange, setMemoryRange] = useState<{ startRange: Date; endRange: Date }>({
    startRange: subDays(Date.now(), 1),
    endRange: new Date(),
  });

  const { name } = useParams<string>();
  const { kubernetesComponents } = useSelector(kubernetesComponentsSelector);

  const dispatch = useDispatch();

  const handleUpdate = async () => {
    if (currentComponent?.namespace && currentComponent.componentName) {
      (async () => {
        const {
          data: { configurationFilenameList, pods },
        } = await getKubernetesComponentLists(currentComponent?.namespace, currentComponent?.componentName);

        setFileNameValue(configurationFilenameList[configurationFilenameList.length - 1]);
        setComponentLists({ configurationFilenameList, pods });
      })();
    }
  };

  const handleChange = ({ name, value }: { name: string; value: string }) => {
    setConfigFileData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    if (currentComponent?.componentName) {
      try {
        const { data } = await createConfigFile(
          configFileData.replicas,
          configFileData.limitMemory,
          configFileData.limitCpu,
          configFileData.consumptionMemory,
          configFileData.consumptionCpu,
          currentComponent.componentName,
        );

        setFileNameValue(data);
        setComponentLists((prevState) => ({
          ...prevState,
          configurationFilenameList: [...prevState.configurationFilenameList, data],
        }));
      } catch (e) {
        console.log(e);
      }
    }
    dispatch(setIsEditing(false));
  };

  useEffect(() => {
    const activeComponent = kubernetesComponents.find((item) => item.componentName === name);
    if (activeComponent) setCurrentComponent(activeComponent);
  }, []);

  useEffect(() => {
    handleUpdate();
  }, [currentComponent]);

  useEffect(() => {
    if (currentComponent?.namespace && componentLists) {
      (async () => {
        try {
          const {
            data: { podContainers, status },
          } = await getContainersOnPod(
            currentComponent?.namespace,
            podValue || componentLists?.pods[0],
            cpuRange.startRange.toISOString().split('.')[0],
            cpuRange.endRange.toISOString().split('.')[0],
            memoryRange.startRange.toISOString().split('.')[0],
            memoryRange.endRange.toISOString().split('.')[0],
          );

          const cpuValues = podContainers.map((item) => ({ label: item.containerName, values: item.valueCPU }));
          const memoryValues = podContainers.map((item) => ({ label: item.containerName, values: item.valueMemory }));

          setMemoryValues(memoryValues);
          setCpuValues(cpuValues);
          dispatch(
            setCounter({
              running: status.running,
              success: status.success,
              failed: status.failed,
              wait: status.waiting,
              queued: 0,
            }),
          );
          setIsLoading(false);
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, [podValue, currentComponent, componentLists.pods, cpuRange, memoryRange]);

  useEffect(() => {
    if (currentComponent && fileNameValue) {
      (async () => {
        try {
          const { data } = await getConfigFilesByComponentName(currentComponent.componentName, fileNameValue);

          const updateValues = {
            ...data,
            limitCpu: Number(data.limit?.cpu.replace(/\D/g, '')),
            limitMemory: Number(data.limit?.memory.replace(/\D/g, '')),
            consumptionCpu: Number(data.request?.cpu.replace(/\D/g, '')),
            consumptionMemory: Number(data.request?.memory.replace(/\D/g, '')),
          };

          setConfigFileData(updateValues);
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, [currentComponent, fileNameValue]);

  if (isLoading) return <Loader />;

  return (
    <>
      <ActionHome
        viewMode='componentPage'
        onRefresh={handleUpdate}
        onEdit={() => setIsModalOpen(true)}
        onSave={handleSave}
        onCancel={() => dispatch(setIsEditing(false))}
      />
      <StyledContentWrapper>
        <ConfigurationParameters
          fileNameList={componentLists?.configurationFilenameList || []}
          fileNameValue={fileNameValue}
          configFileData={configFileData}
          setFileNameValue={setFileNameValue}
          handleChange={handleChange}
        />
        <PodsParameters
          podsLists={componentLists?.pods || []}
          cpuRange={cpuRange}
          setCpuRange={setCpuRange}
          memoryRange={memoryRange}
          setMemoryRange={setMemoryRange}
          podValue={podValue}
          memoryValues={memoryValues}
          cpuValues={cpuValues}
          setPodValue={setPodValue}
        />
        <SaveSettingsModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      </StyledContentWrapper>
    </>
  );
};

import { Counter } from '../types/types';

export const ITEMS = [
  {
    name: 'waiting',
    label: 'Ожидает',
    checked: true,
    value: 'wait' as Counter,
    color: '#D7F8FC',
    backgroundColor: '#2AA2C9',
  },
  {
    name: 'running',
    label: 'Выполняется',
    checked: true,
    value: 'running' as Counter,
    color: '#FFF8E0',
    backgroundColor: '#D9A54C',
  },
  {
    name: 'success',
    label: 'Успешно выполнено',
    checked: true,
    value: 'success' as Counter,
    color: '#D5F0D8',
    backgroundColor: '#0DA955',
  },
  {
    name: 'failed',
    label: 'Ошибки',
    checked: true,
    value: 'failed' as Counter,
    color: '#EDDADF',
    backgroundColor: '#BA346A',
  },
];

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-final-form';
// eslint-disable-next-line import/named
import { LeftSide, Table, TableColumn } from 'nx-design/Table';
import { HeaderSideProps } from 'nx-design/__internal__/src/components/Table/headerMenu';
// eslint-disable-next-line import/named
import { ItemRightSide, RightSide } from 'nx-design/__internal__/src/components/Table/RightSide/RightSide';
import { Button } from 'nx-design/Button';
import { IconDelete, IconEdit } from '../../assets/Assets';
import { RightSideBar } from '../../components/RightSideBar/RightSideBar';
import { basicColumns, getFilters, prepareFormValuesToRequest } from '../../helpers/SettingsScheduleHelpers';
import { DeleteModal } from '../../components/DeleteModal/DeleteModal';
import { WorkflowDeploymentFormValues } from '../../types/types';
import { WorkflowDeplymentForm } from '../../components/WorkflowDeplymentForm/WorkflowDeplymentForm';
import { CronEditor } from '../../components/CronEditor/CronEditor';
import { initialWorkflowDeploymentData } from '../../constants/initialValues';
import { StyledTableSettings } from './style';
import {
  workflowDeploymentsSelector,
  setEditing,
  setWorkflowDeployments,
  deleteWorkflowDeployments,
  createWorkflowDeployments,
  updateWorkflowDeployments,
} from '../../redux/slices/workflowDeploymentsSlice';
import { Loader } from '../../components/Loader/Loader';
import {
  getWorkflowDeployments,
  deleteWorkflowDeployment,
  createWorkflowDeployment,
  updateWorkflowDeployment,
} from '../../api/api';
import { setNotification } from '../../redux/slices/notificationsSlice';
import { ROUTES } from '../../constants/constants';

export const SettingsSchedule = () => {
  const [initialValues, setInitialValues] = useState<WorkflowDeploymentFormValues>(initialWorkflowDeploymentData);
  const [isRightSideBarOpen, setIsRightSideBarOpen] = useState(false);
  const [isRightCronEditorOpen, setIsRightCronEditorOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState<number>();
  const [isLoading, setIsLoading] = useState(true);
  const [lineVisible, setLineVisible] = useState<boolean>(false);
  const { isEditingWfDeployment, workflowDeployments } = useSelector(workflowDeploymentsSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filters = getFilters(workflowDeployments);

  const onDeleteRow = async () => {
    if (deleteRow) {
      try {
        await deleteWorkflowDeployment(deleteRow);
        dispatch(deleteWorkflowDeployments(deleteRow));
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleEditRow = (e_id: number) => {
    const editableRow = workflowDeployments.find((item) => item.e_id === e_id);
    if (editableRow) {
      setInitialValues({
        ...editableRow,
        dagStartDate: new Date(editableRow.dagStartDate),
        airflow: { id: editableRow.airflow.e_id, label: editableRow.airflow.name },
        start: { id: editableRow.start?.e_id, label: editableRow.start?.name },
      });
      setIsRightSideBarOpen(true);
      dispatch(setEditing(true));
    }
  };

  const handleDeleteRow = (e_id: number) => {
    setIsModalOpen(true);
    setDeleteRow(e_id);
  };

  useEffect(() => {
    const columns: TableColumn<any>[] = [
      ...basicColumns,
      {
        title: 'Действия',
        accessor: 'action',
        align: 'center',
        renderCell: ({ e_id }) => (
          <>
            <Button
              view='clear'
              size='xs'
              iconLeft={<IconEdit size='xs' />}
              onlyIcon
              onClick={() => handleEditRow(e_id)}
              style={{ marginRight: 4 }}
            />
            <Button
              view='clear'
              size='xs'
              iconLeft={<IconDelete size='xs' className='test' />}
              onlyIcon
              onClick={() => handleDeleteRow(e_id)}
            />
          </>
        ),
      },
    ];

    setCopyColumns(columns);
  }, [workflowDeployments]);

  const [copyColumns, setCopyColumns] = useState<TableColumn<any>[]>(basicColumns);

  const rightSide: HeaderSideProps = {
    name: RightSide,
    props: {
      nameApplyHiddenColumn: 'Закрыть',
      nameButtonColumnSettings: 'Настроить таблицу',
      overrideHiddenColumns: (newCheckboxGroupValue: ItemRightSide[] | null) => {
        overrideHiddenColumns(newCheckboxGroupValue);
      },
    },
  };

  const leftSide: HeaderSideProps = {
    name: LeftSide,
    props: {
      nameButtonAddColumn: 'Создать',
      nameButtonRefresh: 'Обновить',
      buttonAddType: 'button',
      buttonAddColumnVisible: true,
      onClickButtonAdd: () => setIsRightSideBarOpen(true),
      onClickButtonRefresh: () => updateData(),
    },
  };

  const overrideHiddenColumns = (newCheckboxGroupValue: ItemRightSide[] | null) => {
    const copyColumnsTest = copyColumns.map((column) => {
      const newColumn = { ...column };

      const update: ItemRightSide | undefined =
        newCheckboxGroupValue === null
          ? undefined
          : newCheckboxGroupValue.find((v: ItemRightSide) => newColumn.title === v.title);

      if (update === undefined) {
        if (newColumn.hidden !== undefined) {
          newColumn.hidden = !column.hidden;
        } else if (newColumn.hidden === undefined) {
          newColumn.hidden = true;
        }
        if (newColumn.title === 'id') {
          newColumn.hidden = true;
        }
      }

      return newColumn;
    });

    setCopyColumns(copyColumnsTest);
  };

  const handleFormSubmit = async (values: WorkflowDeploymentFormValues) => {
    const workflowDeployment = prepareFormValuesToRequest(values);

    try {
      const { data } = !isEditingWfDeployment
        ? await createWorkflowDeployment(workflowDeployment)
        : await updateWorkflowDeployment(workflowDeployment);
      dispatch(!isEditingWfDeployment ? createWorkflowDeployments(data) : updateWorkflowDeployments(data));
    } catch (error) {
      dispatch(setNotification({ key: 'error', code: error.response.status, message: error.response.data.message }));
    }
  };

  const setCronStringValue = (value: string) => {
    setInitialValues((prevState) => ({ ...prevState, dagScheduleInterval: value }));
  };

  const updateData = async () => {
    setLineVisible(true);
    try {
      const {
        data: { workflowDeployment },
      } = await getWorkflowDeployments();

      dispatch(setNotification({ key: 'success', code: 200, message: 'Данные успешно загружены' }));
      dispatch(setWorkflowDeployments(workflowDeployment));
    } catch (error) {
      dispatch(setNotification({ key: 'error', code: error.response.status, message: error.message }));
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        navigate(ROUTES.auth);
      }
    }
    setLineVisible(false);
    setIsLoading(false);
  };

  const validateForm = (values: WorkflowDeploymentFormValues) => {
    const errors = {};
    const workflowDeployment = workflowDeployments.find((item) => item.name === values.name);

    if (workflowDeployment && workflowDeployment.e_id !== values.e_id) {
      Object.assign(errors, { name: 'Workflow Deployment с таким названием уже существует' });
    }

    if (!values.name) {
      Object.assign(errors, { name: 'Заполните обязательное поле' });
    }

    return errors;
  };

  useEffect(() => {
    updateData();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <StyledTableSettings>
      <Table
        columns={copyColumns}
        rows={workflowDeployments}
        filters={filters}
        rightSide={rightSide}
        leftSide={leftSide}
        nameResetAllFilters='Сброcить все фильтры'
        size='m'
        stopIsProgressLineVisible={lineVisible}
        separateRows
        withHeaderMenu
        stickyHeader
      />
      <Form initialValues={initialValues} onSubmit={handleFormSubmit} validate={validateForm}>
        {({ handleSubmit, form, valid }) => (
          <RightSideBar
            title={isEditingWfDeployment ? 'Редактирование Workflow Deployment' : 'Создание Workflow Deployment'}
            isOpen={isRightSideBarOpen}
            setIsOpen={setIsRightSideBarOpen}
            onSave={() => {
              if (valid) {
                handleSubmit();
                form.reset();
                setIsRightSideBarOpen(false);
                dispatch(setEditing(false));
              }
            }}
            onClose={() => {
              form.reset();
              dispatch(setEditing(false));
              setInitialValues(initialWorkflowDeploymentData);
            }}
          >
            <WorkflowDeplymentForm setIsRightCronEditorOpen={setIsRightCronEditorOpen} />
          </RightSideBar>
        )}
      </Form>
      <RightSideBar
        title='DAG Schedule Interval'
        isOpen={isRightCronEditorOpen}
        setIsOpen={setIsRightCronEditorOpen}
        onSave={() => {
          setIsRightCronEditorOpen(false);
          dispatch(setEditing(false));
        }}
        onClose={() => {
          dispatch(setEditing(false));
        }}
        isCronEditor
      >
        <CronEditor cronStringValue={initialValues.dagScheduleInterval} setCronStringValue={setCronStringValue} />
      </RightSideBar>
      <DeleteModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} onDelete={onDeleteRow} />
    </StyledTableSettings>
  );
};

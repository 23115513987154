import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Table } from 'nx-design/Table';
import { ActionHome } from '../../components/ActionHome/ActionHome';
import { FlowCounter } from '../../components/FlowCounter/FlowCounter';
import { getDags } from '../../api/api';
import { DagsStateEnum } from '../../types/types';
import { formatDateStr, formatMillisecondsToTime } from '../../helpers/utils';
import { ITEMS, sortOrderDags, columns, filters } from '../../helpers/HomeContainerHelpers';
import { StyledContentWrapper, StyledTable } from '../../styles/common';
import { setDags, dagsSelector } from '../../redux/slices/dagsSlice';
import { setCounter } from '../../redux/slices/counterSlice';
import { Loader } from '../../components/Loader/Loader';
import { subDays, toDate } from 'date-fns';
import { ROUTES } from '../../constants/constants';
import { setNotification } from '../../redux/slices/notificationsSlice';

export const Home = () => {
  const [items, setItems] = useState(ITEMS);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { dags } = useSelector(dagsSelector);

  const updateData = async (startDate: Date, endDate: Date) => {
    try {
      const {
        data: {
          dagStatusAnswer: { countRunningDag, countSuccessDag, countFailedDag, countWaitDag, countQueuedDag, dags },
        },
      } = await getDags(startDate, endDate);

      const rows = dags
        .map((item) => ({
          id: item.dagId,
          state: item.state,
          startDate: formatDateStr(item.startDate),
          endDate: item.endDate ? formatDateStr(item.endDate) : '',
          executionTime: formatMillisecondsToTime(item.executionTime),
        }))
        .sort(
          (a, b) => sortOrderDags.indexOf(a.state as DagsStateEnum) - sortOrderDags.indexOf(b.state as DagsStateEnum),
        );

      dispatch(
        setCounter({
          running: countRunningDag,
          success: countSuccessDag,
          failed: countFailedDag,
          wait: countWaitDag,
          queued: countQueuedDag,
        }),
      );
      dispatch(setDags(rows));
      dispatch(setNotification({ key: 'success', code: 200, message: 'Данные успешно загружены' }));
      setIsLoading(false);
    } catch (error) {
      dispatch(setNotification({ key: 'error', code: error.response.status, message: error.message }));
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        navigate(ROUTES.auth);
      }
      setIsLoading(false);
    }
  };

  const updateCounterItems = (filteredValues: { name: string; value: string }[] | undefined) => {
    if (!filteredValues) {
      const resultItems = items.map((item) => ({ ...item, checked: true }));
      setItems(resultItems);
    } else {
      const resultItems = items.map((item) => {
        const selectedValue = filteredValues.find((value) => value.name === item.label);
        if (selectedValue) return { ...item, checked: true };
        else return { ...item, checked: false };
      });
      setItems(resultItems);
    }
  };

  useEffect(() => {
    const startDate = subDays(Date.now(), 1);
    const endDate = toDate(Date.now());
    updateData(startDate, endDate);
  }, []);

  if (isLoading) return <Loader />;

  return (
    <>
      <ActionHome viewMode='homePage' onRefresh={updateData} />
      <StyledContentWrapper>
        <FlowCounter items={items} />
        <StyledTable>
          <Table
            columns={columns}
            rows={dags}
            filters={filters}
            size='m'
            onFiltersUpdated={({ state: { value } }) => updateCounterItems(value)}
          />
        </StyledTable>
      </StyledContentWrapper>
    </>
  );
};

import React, { useState, useEffect } from 'react';
import { DatePicker } from 'nx-design/DatePicker';
import { TableFilterContainer } from 'nx-design/Table';
import { toDate, subDays } from 'date-fns';

type TableDateFilterProps = {
  onCancel: () => void;
  onConfirm: (value: any) => void;
};

export const TableDateFilter = ({ onCancel, onConfirm }: TableDateFilterProps) => {
  const [startRange, setStartRange] = useState<Date | null>(null);
  const [endRange, setEndRange] = useState<Date | null>(null);

  useEffect(() => {
    setStartRange(subDays(Date.now(), 1));
    setEndRange(toDate(Date.now()));
  }, []);

  return (
    // @ts-ignore
    <TableFilterContainer onCancel={onCancel} onConfirm={() => onConfirm({ min: startRange, max: endRange })}>
      <div style={{ display: 'flex' }}>
        <DatePicker
          type='date-time'
          formatMask='MM.dd.yyyy HH:mm:ss'
          value={startRange}
          onChange={({ value }) => setStartRange(value)}
          style={{ marginRight: 3 }}
        />
        <DatePicker
          formatMask='MM.dd.yyyy HH:mm:ss'
          type='date-time'
          value={endRange}
          onChange={({ value }) => setEndRange(value)}
        />
      </div>
    </TableFilterContainer>
  );
};

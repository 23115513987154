import React from 'react';
import { Typography } from 'nx-design/Typography';
import { IconSpinner } from '../../assets/Assets';
import { StyledLoaderWrapper } from './style';

export const Loader = () => {
  return (
    <StyledLoaderWrapper>
      <IconSpinner size='l' view='link' style={{ marginBottom: 19 }} />
      <Typography size='m'>Секундочку... Идет процесс загрузки данных</Typography>
    </StyledLoaderWrapper>
  );
};

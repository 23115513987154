import React, { useState, useEffect } from 'react';
import { Radio } from 'nx-design/Radio';
import { Typography } from 'nx-design/Typography';
import { Select } from 'nx-design/Select';
import { StyledCollapse, StyledCard, StyledCardItem } from '../style';
import { CronFieldsProps, Item } from '../../../types/types';
import { optionsEveryMinutes, optionsMinutesStart } from '../../../constants/constants';

export const Minutes = ({ value, setValue }: CronFieldsProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isRadio, setIsRadio] = useState('isEveryMinute');
  const [everyMinute, setEveryMinute] = useState<Item | null>(optionsEveryMinutes[0]);
  const [minuteStart, setMinuteStart] = useState<Item | null>(optionsMinutesStart[0]);

  useEffect(() => {
    switch (isRadio) {
      case 'isEveryMinute':
        setValue({ type: isRadio, value: [] });
        break;
      case 'isEveryDefiniteMinute':
        setValue({ type: isRadio, value: [everyMinute?.id!, minuteStart?.id!] });
        break;
      case 'isPeriod':
        break;
    }
  }, [isRadio, everyMinute, minuteStart, setValue]);

  return (
    <StyledCollapse label='Минуты' iconPosition='right' isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
      {/* @ts-ignore */}
      <StyledCard horizontalSpace='m' verticalSpace='m'>
        <StyledCardItem>
          <Radio
            label='Каждую минуту'
            checked={isRadio === 'isEveryMinute'}
            onChange={() => setIsRadio('isEveryMinute')}
          />
        </StyledCardItem>
        <StyledCardItem>
          <Radio
            label='Каждую'
            checked={isRadio === 'isEveryDefiniteMinute'}
            onChange={() => setIsRadio('isEveryDefiniteMinute')}
          />
          <Select
            items={optionsEveryMinutes}
            value={everyMinute}
            onChange={({ value }) => setEveryMinute(value)}
            style={{ width: 80 }}
          />
          <Typography size='m'>минуту, начиная с</Typography>
          <Select
            items={optionsMinutesStart}
            value={minuteStart}
            onChange={({ value }) => setMinuteStart(value)}
            style={{ width: 80 }}
          />
        </StyledCardItem>
        <Radio
          label='Каждная конкретная минута/Период'
          checked={isRadio === 'isPeriod'}
          onChange={() => setIsRadio('isPeriod')}
          style={{ marginBottom: '8px' }}
        />
        {/* <Select placeholder='Выберите' items={minutes} value={value} onChange={({ value }) => setValue(value)} /> */}
      </StyledCard>
    </StyledCollapse>
  );
};

import React from 'react';
import { Grid, GridItem } from 'nx-design/Grid';
import { ReactComponent as AppLogo } from '../../assets/logo.svg';
import { LoginState } from '../../types/types';
import {
  LoginWrapper,
  StyledButton,
  StyledTextField,
  StyledIconGlobe,
  StyledSubmitButton,
  StyledTypography,
} from './styles';

type LoginProps = {
  lang: string;
  logInData: LoginState;
  error: string;
  onSubmit: () => void;
  onChange: (fieldName: string, fieldValue: string | null) => void;
  onChangeLang: () => void;
};

export const Login = ({ lang, logInData, error, onSubmit, onChange, onChangeLang }: LoginProps) => {
  return (
    <LoginWrapper>
      <Grid cols='1' xAlign='center' yAlign='center' rowGap='xl'>
        <Grid cols='2' style={{ marginBottom: 25 }}>
          <GridItem col='1'>
            <AppLogo />
          </GridItem>
          <GridItem col='1' style={{ textAlign: 'right' }}>
            {/* @ts-ignore */}
            <StyledButton
              name='button-lang'
              view='clear'
              size='m'
              iconLeft={<StyledIconGlobe size='xs' />}
              label={lang}
              onClick={onChangeLang}
            />
          </GridItem>
        </Grid>
        <Grid rowGap='s'>
          <GridItem>
            <StyledTextField
              name='login'
              label='Логин'
              value={logInData?.username}
              onChange={({ value }) => onChange('username', value)}
              withClearButton={true}
            />
          </GridItem>
          <GridItem>
            <StyledTextField
              name='password'
              label='Пароль'
              type='password'
              value={logInData?.password}
              onChange={({ value }) => onChange('password', value)}
              withClearButton={true}
            />
          </GridItem>
          {/* @ts-ignore */}
          <StyledTypography>{error}</StyledTypography>
        </Grid>
        <GridItem>
          {/* @ts-ignore */}
          <StyledSubmitButton size='l' label='Войти' onClick={onSubmit} />
        </GridItem>
      </Grid>
    </LoginWrapper>
  );
};

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { CounterState } from '../../types/types';

const initialState = {
  counterState: {} as CounterState,
};

const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setCounter: (state, { payload }: PayloadAction<CounterState>) => {
      state.counterState = payload;
    },
  },
});

export const counterReducer = counterSlice.reducer;
export const counterSelector = (state: RootState) => state.counter;
export const { setCounter } = counterSlice.actions;

import styled from 'styled-components';
import { Menu } from 'antd';

export const StyledSubMenuTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
`;

export const StyledSidebar = styled.div<{ collapsed: boolean }>(
  ({ collapsed }) => `
  border-right: 1px solid #e6e6e6;
  min-width: ${collapsed ? '296px' : '48px'};
  height: 100vh;
  `,
);

export const StyledMenuItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const StyledSidebarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  height: 63px;
  border-bottom: 1px solid #e6e6e6;
`;

export const StyledMenu = styled(Menu)<{ collapsed: boolean }>(
  ({ collapsed }) => `
  background-color: #ffffff;
  color: #404040;
  margin-top: 5px;
  padding: 0px 9px;
  position: sticky;
  top: 0;
  
  li.ant-menu-item-selected {
    background: #f2f2f2;
    border-radius: 4px;
  }
  
  li.ant-menu-item-selected > .ant-menu-title-content {
    font-weight: 600;
    color: #333333;
  }

  ul.ant-menu-hidden {
    display: none;
  }
  
  & .ant-menu-item-icon {
    margin-right: ${collapsed ? '10px' : '0'};
  }
  
  .ant-menu-item:hover svg, .ant-menu-submenu-title:hover svg {
    fill: ${collapsed ? 'currentcolor' : 'white'}
  }

  & .ant-menu-submenu-inline > .ant-menu-submenu-title .IconChevronUp {
    transition: transform .3s;
    transform: rotate(180deg);
  }

  & .ant-menu-submenu-open > .ant-menu-submenu-title .IconChevronUp {
    transition: transform .3s;
    transform: rotate(0deg);
  }

  > .ant-menu-item,
  .ant-menu-submenu-title {
    margin-bottom: 8px;
    padding: 6px 0px 6px 6px !important;
  }
  
  .ant-menu-item:hover,
  .ant-menu-submenu-title:hover {
    background: ${collapsed ? '#f2f2f2' : '#2AA2C9'};
    border-radius: 4px;
  }

  .ant-menu-item-only-child > .ant-menu-title-content {
    font-weight: 400;
    font-size: 14px;
    color: #666666;
  }
  
  .ant-menu-submenu .ant-menu-submenu {
    padding-left: 42px;
  }
  
  .ant-menu-submenu .ant-menu-submenu .ant-menu-submenu-title:hover {
    background: none;
  }
  
  .ant-menu-submenu .ant-menu-submenu .ant-menu-title-content span {
    font-weight: 400;
    font-size: 14px;
    color: #666666;
  }
`,
);

export const StyledSubMenu = styled(Menu.SubMenu)<{ collapsed?: boolean }>(
  ({ collapsed }) => `
  cursor: pointer;

  .ant-menu-sub {
    transition: height 0.1s ease;
  }

  .ant-menu-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 9px 0px;
    &:hover .ant-menu-title-content {
      font-weight: 600;
    }
  }
  
  .ant-menu-sub .ant-menu-sub li {
    padding-left 30px !important;
  }

  > .ant-menu-submenu-title {
    display: flex;
    align-items: center;
  }

  > .ant-menu {
    display: ${collapsed ? 'none' : 'block'};
  }

  > .ant-menu-sub {
    margin-left: 7px;
  }

  .ant-menu-title-content {
    flex: auto;
  }
  .ant-menu-submenu-title > .IconChevronUp {
    display: ${collapsed ? 'none' : 'block'};
    margin-right: 7px;
  }
}
`,
);

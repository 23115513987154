import React from 'react';
// eslint-disable-next-line import/named
import { TableColumn, TableTextFilter } from 'nx-design/Table';
import { WorkflowDeployment, WorkflowDeploymentFormValues } from '../types/types';
import { TableDateFilter } from '../components/TableDateFilter/TableDateFilter';
import { filtersDate, filterMultipleValues, formatDateStr } from './utils';
import { withTooltip } from 'nx-design/withTooltip';
import { Typography } from 'nx-design/Typography';

const TextWithTooltip = withTooltip({ size: 's', className: 'Styled-Tooltip', mode: 'mouseover' })(Typography);

export const prepareFormValuesToRequest = (values: WorkflowDeploymentFormValues) => {
  const data = {
    name: values.name,
    dagStartDate: values.dagStartDate,
    dagScheduleInterval: values.dagScheduleInterval,
    airflow: {
      name: values.airflow?.label,
      e_id: values.airflow?.id,
      _type_: 'rt.Airflow',
    },
    start: {
      name: values.start?.label,
      e_id: values.start?.id,
      _type_: 'etl.Workflow',
    },
    e_id: values.e_id ? values.e_id : undefined,
    _type_: 'rt.WorkflowDeployment',
  };

  return data;
};

export const basicColumns: TableColumn<any>[] = [
  {
    title: 'id',
    accessor: 'id',
    hidden: true,
  },
  {
    title: 'Workflow Deployment',
    accessor: 'name',
    align: 'left',
    width: 300,
    sortable: true,
    renderCell: (row) => (
      <TextWithTooltip className='TableCell-Wrapper_wrap_truncate' size='m' tooltipProps={{ content: row.name }}>
        {row.name}
      </TextWithTooltip>
    ),
  },
  {
    title: 'DAG Start Date',
    accessor: 'dagStartDate',
    align: 'center',
    sortable: true,
    renderCell: ({ dagStartDate }) => <>{dagStartDate ? formatDateStr(dagStartDate) : ''}</>,
  },
  {
    title: 'DAG Schedule Interval',
    accessor: 'dagScheduleInterval',
    align: 'center',
    sortable: true,
  },
  {
    title: 'Airflow',
    accessor: 'airflow',
    align: 'left',
    sortable: true,
    renderCell: (row) => (
      <Typography size='m' style={{ color: '#2AA2C9' }}>
        {row.airflow?.name}
      </Typography>
    ),
  },
  {
    title: 'Workflow',
    accessor: 'start',
    align: 'left',
    sortable: true,
    renderCell: (row) => <Typography size='m'>{row.start?.name}</Typography>,
  },
];

export const basicFilters = [
  {
    id: 'dagStartDate',
    name: 'Диапазон начала: ',
    field: 'dagStartDate',
    filterer: filtersDate,
    component: {
      name: TableDateFilter,
    },
  },
];

export const getFilters = (rows: WorkflowDeployment[]) => {
  return [
    ...basicFilters,
    {
      id: 'name',
      name: 'Workflow Deployment: ',
      field: 'name',
      filterer: filterMultipleValues,
      component: {
        name: TableTextFilter,
        props: {
          withSearch: true,
          items: rows.map((item) => ({ name: item.name, value: item.name })),
        },
      },
    },
    {
      id: 'dagScheduleInterval',
      name: 'DAG Schedule Interval: ',
      field: 'dagScheduleInterval',
      filterer: filterMultipleValues,
      component: {
        name: TableTextFilter,
        props: {
          withSearch: true,
          items: rows.map((item) => ({ name: item.dagScheduleInterval, value: item.dagScheduleInterval })),
        },
      },
    },
    {
      id: 'airflow',
      name: 'Airflow: ',
      field: 'airflow',
      filterer: filterMultipleValues,
      component: {
        name: TableTextFilter,
        props: {
          withSearch: true,
          items: rows.map((item) => ({ name: item.airflow, value: item.airflow })),
        },
      },
    },
    {
      id: 'workflow',
      name: 'Workflow: ',
      field: 'workflow',
      filterer: filterMultipleValues,
      component: {
        name: TableTextFilter,
        props: {
          withSearch: true,
          items: rows.map((item) => ({ name: item.start, value: item.start })),
        },
      },
    },
  ];
};

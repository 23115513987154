import React, { useState, useEffect } from 'react';
import { Radio } from 'nx-design/Radio';
import { Typography } from 'nx-design/Typography';
import { Select } from 'nx-design/Select';
import { StyledCollapse, StyledCard, StyledCardItem } from '../style';
import { CronFieldsProps, Item } from '../../../types/types';
import { optionsEveryMonths, optionsMonthsStart } from '../../../constants/constants';

export const Month = ({ value, setValue }: CronFieldsProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isRadio, setIsRadio] = useState('isEveryMonth');
  const [everyMonths, setEveryMonths] = useState<Item | null>(optionsEveryMonths[0]);
  const [monthStart, setMonthStart] = useState<Item | null>(optionsMonthsStart[0]);

  useEffect(() => {
    switch (isRadio) {
      case 'isEveryMonth':
        setValue({ type: isRadio, value: [] });
        break;
      case 'isEveryDefiniteMonth':
        setValue({ type: isRadio, value: [everyMonths?.id!, monthStart?.id!] });
        break;
      case 'isPeriod':
        break;
    }
  }, [isRadio, everyMonths, monthStart, setValue]);

  return (
    <StyledCollapse label='Месяц' iconPosition='right' isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
      {/* @ts-ignore */}
      <StyledCard horizontalSpace='m' verticalSpace='m'>
        <StyledCardItem>
          <Radio
            label='Каждый месяц'
            checked={isRadio === 'isEveryMonth'}
            onChange={() => setIsRadio('isEveryMonth')}
          />
        </StyledCardItem>
        <StyledCardItem>
          <Radio
            label='Каждый'
            checked={isRadio === 'isEveryDefiniteMonth'}
            onChange={() => setIsRadio('isEveryDefiniteMonth')}
          />
          <Select
            items={optionsEveryMonths}
            value={everyMonths}
            onChange={({ value }) => setEveryMonths(value)}
            style={{ width: 80 }}
          />
          <Typography size='m'>месяц, начиная с</Typography>
          <Select
            items={optionsMonthsStart}
            value={monthStart}
            onChange={({ value }) => setMonthStart(value)}
            style={{ width: 80 }}
          />
        </StyledCardItem>
        <StyledCardItem column start>
          <Radio
            label='Каждый конкретный месяц/Период'
            checked={isRadio === 'isPeriod'}
            onChange={() => setIsRadio('isPeriod')}
            style={{ marginBottom: '8px' }}
          />
          {/* <Select
            placeholder='Выберите'
            items={monthsItems}
            value={monthStart}
            onChange={({ value }) => setValue(value)}
          /> */}
        </StyledCardItem>
      </StyledCard>
    </StyledCollapse>
  );
};

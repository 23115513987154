import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumbs } from 'nx-design/Breadcrumbs';
import { HeaderModule } from 'nx-design/Header';
import { Typography } from 'nx-design/Typography';
import { Button } from 'nx-design/Button';
import { getItemsForBreadcrumbs } from '../../helpers/utils';
import { IconInfo, IconUser, IconSignOut, IconGlobe } from '../../assets/Assets';
import { StyledDivider } from '../../styles/common';
import { StyledHeader, StyledProfile, StyledHeaderModule } from './styles';
import { ROUTES } from '../../constants/constants';
import { userSelector, removeUser } from '../../redux/slices/userSlice';

export const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { username } = useSelector(userSelector);
  const ITEMS = getItemsForBreadcrumbs(pathname);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('roles');
    dispatch(removeUser());
    navigate(ROUTES.auth);
  };

  return (
    <>
      <StyledHeader
        leftSide={<Breadcrumbs items={ITEMS} fitMode='scroll' lastItemIsLink />}
        rightSide={
          <>
            <StyledHeaderModule>
              <Button name='button-lang' view='clear' size='m' iconLeft={<IconGlobe size='xs' />} label='RU' />
              <StyledDivider />
              <Button view='clear' iconLeft={<IconInfo size='xs' />} onlyIcon />
            </StyledHeaderModule>
            <StyledHeaderModule indent='m'>
              <StyledProfile>
                <IconUser size='xs' style={{ color: 'white' }} />
              </StyledProfile>
              <Typography weight='medium'>{username}</Typography>
            </StyledHeaderModule>
            <HeaderModule indent='l'>
              <Button
                label='Скрыть'
                view='clear'
                iconLeft={<IconSignOut size='xs' />}
                onlyIcon
                onClick={handleLogout}
              />
            </HeaderModule>
          </>
        }
      />
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { useFormState, useForm, Field } from 'react-final-form';
import { TextField } from 'nx-design/TextField';
import { DatePicker } from 'nx-design/DatePicker';
import { Combobox } from 'nx-design/Combobox';
import { IconEdit } from '../../assets/Assets';
import { StyledFieldWrapper, StyledLabel, StyledWrapper } from './style';
import { formatStringToDate } from '../../helpers/utils';
import { getAirflows, getWorkflows } from '../../api/api';
import { Item } from '../../types/types';

type WorkflowDeplymentFormProps = {
  setIsRightCronEditorOpen: (isOpen: boolean) => void;
};

export const WorkflowDeplymentForm = ({ setIsRightCronEditorOpen }: WorkflowDeplymentFormProps) => {
  const [airflowsItems, setAirflowsItems] = useState<Item[]>([]);
  const [workflowsItems, setWorkflowsItems] = useState<Item[]>([]);

  const {
    values: {
      name,
      dagStartDate,
      dagScheduleInterval,
      airflow,
      start,
      createDateTime,
      createUser,
      changeDateTime,
      changeUser,
    },
  } = useFormState();

  const { change } = useForm();

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { airflows },
        } = await getAirflows();
        const {
          data: { workflows },
        } = await getWorkflows();

        const mappingAirflowsItems = airflows.map((item) => ({ label: item.name, id: item.e_id }));
        const mappingWorkflowsItems = workflows.map((item) => ({ label: item.name, id: item.e_id }));
        setAirflowsItems(mappingAirflowsItems);
        setWorkflowsItems(mappingWorkflowsItems);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <StyledWrapper>
      <Field name='name'>
        {({ input, meta }) => (
          <TextField
            {...input}
            name='name'
            label='Наименование'
            type='text'
            value={name}
            onChange={({ value }) => change('name', value)}
            width='full'
            status={meta.error && meta.touched && 'error'}
            caption={meta.error && meta.touched && meta.error}
            style={{ marginBottom: 16 }}
            required
          />
        )}
      </Field>
      <StyledFieldWrapper>
        <DatePicker
          name='dagStartDate'
          label='DAG Start Date'
          placeholder='ГГГГ-ММ-ДД'
          type='date-time'
          value={dagStartDate}
          onChange={({ value }) => change('dagStartDate', value)}
        />
        <TextField
          name='dagScheduleInterval'
          label='DAG Schedule Interval'
          placeholder='*****'
          type='text'
          value={dagScheduleInterval}
          onChange={({ value }) => change('dagScheduleInterval', value)}
          onClick={() => setIsRightCronEditorOpen(true)}
          rightSide={IconEdit}
        />
      </StyledFieldWrapper>
      <div>
        {/* @ts-ignore */}
        <StyledLabel size='m'>Airflow</StyledLabel>
        <Combobox
          name='Airflow'
          placeholder='Выберите из списка'
          items={airflowsItems}
          value={airflow}
          onChange={({ value }) => change('airflow', value)}
          style={{ marginBottom: 16 }}
        />
      </div>
      <div>
        {/* @ts-ignore */}
        <StyledLabel size='m'>Workflow</StyledLabel>
        <Combobox
          name='workflow'
          placeholder='Выберите из списка'
          items={workflowsItems}
          value={start}
          onChange={({ value }) => change('start', value)}
          style={{ marginBottom: 16 }}
        />
      </div>
      <StyledFieldWrapper>
        <DatePicker
          name='createDateTime'
          placeholder='ГГГГ-ММ-ДД ЧЧ:ММ:СС'
          label='Дата создания'
          type='date-time'
          value={formatStringToDate(createDateTime)}
          disabled
        />
        <TextField
          name='createUser'
          label='Автор создания'
          type='text'
          value={createUser}
          withClearButton={false}
          disabled
        />
      </StyledFieldWrapper>
      <StyledFieldWrapper>
        <DatePicker
          name='changeDateTime'
          placeholder='ГГГГ-ММ-ДД ЧЧ:ММ:СС'
          label='Дата изменения'
          type='date-time'
          value={changeDateTime}
          disabled
        />
        <TextField
          name='changeUser'
          label='Автор изменения'
          type='text'
          value={changeUser}
          withClearButton={false}
          disabled
        />
      </StyledFieldWrapper>
    </StyledWrapper>
  );
};

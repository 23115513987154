import styled from 'styled-components';
import { Collapse } from 'nx-design/Collapse';
import { Card } from 'nx-design/Card';

export const StyledWrapper = styled.div`
  .Select,
  .Radio,
  .Typography {
    margin-right: 16px;
  }
  .Card > div:not(:last-child) {
    margin-bottom: 22px;
  }
  padding: 0px 24px 0 24px;
`;

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  background: white;
`;

export const StyledCardItem = styled.div<{ column?: boolean; start?: boolean }>(
  ({ column, start }) => `
  display: flex;
  align-items: center;
  flex-direction: ${column ? 'column' : 'row'};
  align-items: ${start ? 'start' : 'center'}
`,
);

export const StyledCollapse = styled(Collapse)`
  border-bottom: 1px solid var(--color-base-base-grey-3);
  .Collapse-Label > .Typography {
    color: var(--color-base-base-grey-8);
  }
`;

export const StyledTextFieldWrapper = styled.div`
  background: var(--color-bg-default);
  padding: 16px 24px;
  border-bottom: 1px solid var(--color-base-base-grey-3);
  position: sticky;
  top: 51px;
  z-index: 22;
`;

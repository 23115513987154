import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  key: '',
  code: 0,
  message: '',
};

const notificationsSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification: (state, { payload }: PayloadAction<{ key: string; code: number; message: string }>) => {
      state.key = payload.key;
      state.code = payload.code;
      state.message = payload.message;
    },
    deleteNotification: (state) => {
      state.key = '';
      state.code = 0;
      state.message = '';
    },
  },
});

export const notificationsReducer = notificationsSlice.reducer;
export const notificationsSelector = (state: RootState) => state.notification;
export const { setNotification, deleteNotification } = notificationsSlice.actions;

import styled from 'styled-components';

export const StyledActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  background-color: white;
  border-bottom: 1px solid #e6e6e6;
`;

export const StyledActionBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledDateWrapper = styled.div`
  margin-left: 8px;
`;

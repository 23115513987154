import React, { useState, useEffect } from 'react';
import { Radio } from 'nx-design/Radio';
import { Typography } from 'nx-design/Typography';
import { Select } from 'nx-design/Select';
import { StyledCollapse, StyledCard, StyledCardItem } from '../style';
import { CronFieldsProps, Item } from '../../../types/types';
import { optionsEveryHours, optionsHoursStart } from '../../../constants/constants';

export const Hours = ({ value, setValue }: CronFieldsProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isRadio, setIsRadio] = useState('isEveryHour');
  const [everyHour, setEveryHour] = useState<Item | null>(optionsEveryHours[0]);
  const [hourStart, setHourStart] = useState<Item | null>(optionsHoursStart[0]);

  useEffect(() => {
    switch (isRadio) {
      case 'isEveryHour':
        setValue({ type: isRadio, value: [] });
        break;
      case 'isEveryDefiniteHour':
        setValue({ type: isRadio, value: [everyHour?.id!, hourStart?.id!] });
        break;
      case 'isPeriod':
        break;
    }
  }, [isRadio, everyHour, hourStart, setValue]);

  return (
    <StyledCollapse label='Часы' iconPosition='right' isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
      {/* @ts-ignore */}
      <StyledCard horizontalSpace='m' verticalSpace='m'>
        <StyledCardItem>
          <Radio label='Каждый час' checked={isRadio === 'isEveryHour'} onChange={() => setIsRadio('isEveryHour')} />
        </StyledCardItem>
        <StyledCardItem>
          <Radio
            label='Каждый'
            checked={isRadio === 'isEveryDefiniteHour'}
            onChange={() => setIsRadio('isEveryDefiniteHour')}
          />
          <Select
            items={optionsEveryHours}
            value={everyHour}
            onChange={({ value }) => setEveryHour(value)}
            style={{ width: 80 }}
          />
          <Typography size='m'>час, начиная с</Typography>
          <Select
            items={optionsHoursStart}
            value={hourStart}
            onChange={({ value }) => setHourStart(value)}
            style={{ width: 80 }}
          />
        </StyledCardItem>
        <Radio
          label='Каждый конкретный час/Период'
          checked={isRadio === 'isPeriod'}
          onChange={() => setIsRadio('isPeriod')}
          style={{ marginBottom: '8px' }}
        />
        {/* <Select placeholder='Выберите' items={minutes} value={value} onChange={({ value }) => setValue(value)} /> */}
      </StyledCard>
    </StyledCollapse>
  );
};

import React from 'react';
import { Button } from 'nx-design/Button';
import {
  StyledModalTitle,
  StyledModalText,
  StyledContent,
  StyledButtonWrapper,
  StyledModal,
} from '../../styles/common';
import { IconDelete, IconBlock } from '../../assets/Assets';

type DeleteModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onDelete: () => void;
};

export const DeleteModal = ({ isOpen, setIsOpen, onDelete }: DeleteModalProps) => {
  return (
    <StyledModal isOpen={isOpen} hasOverlay onOverlayClick={() => setIsOpen(false)}>
      <StyledContent>
        {/* @ts-ignore */}
        <StyledModalTitle size='l' view='secondary'>
          Удаление настройки
        </StyledModalTitle>
        <StyledModalText size='m' view='primary'>
          Вы действительно хотите удалить настройку?
        </StyledModalText>
      </StyledContent>
      <StyledButtonWrapper>
        <Button
          size='m'
          view='primary'
          label='Удалить'
          iconLeft={IconDelete}
          onClick={() => {
            onDelete();
            setIsOpen(false);
          }}
          style={{ marginRight: 16 }}
        />
        <Button size='m' view='secondary' label='Отменить' iconLeft={IconBlock} onClick={() => setIsOpen(false)} />
      </StyledButtonWrapper>
    </StyledModal>
  );
};

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { UserState } from '../types';

const initialState: UserState = {
  username: JSON.parse(localStorage.getItem('username') || 'null'),
  roles: JSON.parse(localStorage.getItem('roles') || 'null'),
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload: { roles, username } }: PayloadAction<UserState>) => {
      state.roles = roles;
      state.username = username;
    },
    removeUser: (state) => {
      state.roles = [];
      state.username = '';
    },
  },
});

export const userReducer = userSlice.reducer;
export const userSelector = (state: RootState) => state.user;
export const { setUser, removeUser } = userSlice.actions;

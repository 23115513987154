import { format, parseISO } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';

import { CALENDAR_DATE_WITH_DOT_SEPARATOR, ROUTES } from '../constants/constants';
import { IconBell, IconCloudDownload, IconCode, IconDataset, IconGrid } from '../assets/Assets';
import { DagsStateEnum, DefaultItem } from '../types/types';

export const getItemsForBreadcrumbs = (pathname: string): DefaultItem[] => {
  const defaultItems = [
    {
      label: 'Главная',
      icon: IconGrid,
      href: ROUTES.home,
    },
  ];

  switch (pathname) {
    case ROUTES.executionHistory:
      return [
        ...defaultItems,
        { label: 'Потоки обработки данных', icon: IconDataset },
        { label: 'История выполнения', href: ROUTES.executionHistory },
      ];
    case ROUTES.settingsSchedule:
      return [
        ...defaultItems,
        { label: 'Потоки обработки данных', icon: IconDataset },
        { label: 'Настройка расписания', href: ROUTES.settingsSchedule },
      ];
    case ROUTES.airflow:
      return [
        ...defaultItems,
        { label: 'Логирование', icon: IconCode },
        { label: 'ETL процессы' },
        { label: 'Airflow', href: ROUTES.airflow },
      ];
    case ROUTES.sparkHistoryServer:
      return [
        ...defaultItems,
        { label: 'Логирование', icon: IconCode },
        { label: 'ETL процессы' },
        { label: 'Spark history server', href: ROUTES.sparkHistoryServer },
      ];
    case ROUTES.livy:
      return [
        ...defaultItems,
        { label: 'Логирование', icon: IconCode },
        { label: 'ETL процессы' },
        { label: 'livy', href: ROUTES.livy },
      ];
    case ROUTES.securityAudit:
      return [
        ...defaultItems,
        { label: 'Логирование', icon: IconCode },
        { label: 'Аудит безопасности', href: ROUTES.securityAudit },
      ];
    case ROUTES.logging:
      return [
        ...defaultItems,
        { label: 'Среда исполнения', icon: IconCloudDownload },
        { label: 'Логирование', href: ROUTES.logging },
      ];
    case ROUTES.configuringEnvironment:
      return [
        ...defaultItems,
        { label: 'Среда исполнения', icon: IconCloudDownload },
        { label: 'Настройка', href: ROUTES.configuringEnvironment },
      ];
    case ROUTES.notificationSettings:
      return [...defaultItems, { label: 'Метрики', icon: IconBell, href: ROUTES.notificationSettings }];
    default:
      return defaultItems;
  }
};

export const isAuthorized = () => JSON.parse(localStorage.getItem('token') || 'null');

export const getStatusElementsStyle = (status: any) => {
  switch (status) {
    case DagsStateEnum.WAITING:
      return 'background: #D7F8FC; color: #1A7CA3 !important;';
    case DagsStateEnum.RUNNING:
      return 'background: #FFECB8; color: #B38136 !important;';
    case DagsStateEnum.SUCCESS:
      return 'background: #D5F0D8; color: #09874A !important;';
    case DagsStateEnum.FAILED:
      return 'background: #EDDADF; color: #AD1457 !important;';
    case DagsStateEnum.QUEUED:
      return 'background: #F2F2F2; color: #404040 !important;';
  }
};

export const changeDagsStateName = (state: string | undefined) => {
  switch (state) {
    case DagsStateEnum.WAITING:
      return 'Ожидает';
    case DagsStateEnum.RUNNING:
      return 'Выполняется';
    case DagsStateEnum.SUCCESS:
      return 'Успешно выполнено';
    case DagsStateEnum.FAILED:
      return 'Ошибка';
    default:
      return 'В очереди';
  }
};

export const formatDate = (date: Date | number, formatResult: string = CALENDAR_DATE_WITH_DOT_SEPARATOR) =>
  format(date, formatResult, { locale: ruLocale });

export const formatDateStr = (date: string, formatResult: string = CALENDAR_DATE_WITH_DOT_SEPARATOR) => {
  const parseDate = date.split(', ');
  if (parseDate.length === 4) {
    return `${parseDate[0]}.${parseDate[1]}.${parseDate[2]} ${parseDate[3]}:00:00`;
  } else {
    return formatDate(parseISO(date), formatResult);
  }
};

export const formatMillisecondsToTime = (t: number) => {
  const days = Math.floor((t / (1000 * 60 * 60 * 24)) % 30);
  let hours: number | string = Math.floor((t / (1000 * 60 * 60)) % 24);
  let minutes: number | string = Math.floor((t / (1000 * 60)) % 60);
  let seconds: number | string = Math.round((t / 1000) % 60);

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  return days
    ? `${days} ${declOfNum(days, ['день', 'дня', 'дней'])} ${hours}:${minutes}:${seconds}`
    : `${hours}:${minutes}:${seconds}`;
};

export const formatStringToDate = (date: string) => parseISO(date?.split(' ').join('T').split('.').join('-'));

export const formatStringTimeToDate = (time: string) => {
  const date = new Date();
  const [hours, minutes, sec] = time.split(':');
  date.setHours(Number(hours));
  date.setMinutes(Number(minutes));
  date.setSeconds(Number(sec));
  return date;
};

export const filtersDate = (cellValue: string, filterValue: { min: Date; max: Date }) =>
  formatStringToDate(cellValue) >= filterValue.min && formatStringToDate(cellValue) <= filterValue.max;

export const filterMultipleValues = (cellValue: string, filterValues: Array<{ value: string; name: string }>) =>
  filterValues.some((filterValue) => filterValue && filterValue.value === cellValue);

const declOfNum = (number: number, words: string[]) => {
  return words[
    number % 100 > 4 && number % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
  ];
};

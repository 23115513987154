import React from 'react';
// eslint-disable-next-line import/named
import { TableColumn, TableTextFilter } from 'nx-design/Table';
import { EnvironmentComponent } from '../types/types';
import { TableDateFilter } from '../components/TableDateFilter/TableDateFilter';
import { filtersDate, filterMultipleValues, formatDateStr } from './utils';

export const basicColumns: TableColumn<any>[] = [
  {
    title: 'id',
    accessor: 'id',
    hidden: true,
  },
  {
    title: 'Компонент',
    accessor: 'componentName',
    align: 'left',
    width: 600,
    sortable: true,
  },
  {
    title: 'Дата изменения',
    accessor: 'createDate',
    align: 'center',
    sortable: true,
    renderCell: ({ createDate }) => <>{createDate ? formatDateStr(createDate) : ''}</>,
  },
  {
    title: 'Автор изменения',
    accessor: 'createUser',
    align: 'left',
    sortable: true,
  },
];

export const basicFilters = [
  {
    id: 'updateDate',
    name: 'Диапазон изменения: ',
    field: 'updateDate',
    filterer: filtersDate,
    component: {
      name: TableDateFilter,
    },
  },
];

export const getFilters = (rows: EnvironmentComponent[]) => {
  return [
    ...basicFilters,
    {
      id: 'label',
      name: 'Компонент: ',
      field: 'componentName',
      filterer: filterMultipleValues,
      component: {
        name: TableTextFilter,
        props: {
          withSearch: true,
          items: rows.map((item) => ({ name: item.componentName, value: item.componentName })),
        },
      },
    },
    {
      id: 'updateUser',
      name: 'Автор изменения: ',
      field: 'createUser',
      filterer: filterMultipleValues,
      component: {
        name: TableTextFilter,
        props: {
          withSearch: true,
          items: rows.map((item) => ({ name: item.createUser, value: item.createUser })),
        },
      },
    },
  ];
};

export const initialWorkflowDeploymentData = {
  name: '',
  dagStartDate: null,
  dagScheduleInterval: '*****',
  airflow: null,
  start: null,
  createDateTime: '',
  createUser: '',
  changeDateTime: '',
  changeUser: '',
};

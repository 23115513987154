import styled from 'styled-components';

export const StyledLoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
  .Icon-Svg {
    animation: Spin 2s infinite linear;
  }
  @keyframes Spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

import React, { useState, useEffect } from 'react';
import { Radio } from 'nx-design/Radio';
import { Typography } from 'nx-design/Typography';
import { Select } from 'nx-design/Select';
import { StyledCollapse, StyledCard, StyledCardItem } from '../style';
import { CronFieldsProps, Item } from '../../../types/types';
import { optionsEveryDays, optionsDayStart, optionsNextWeekDay } from '../../../constants/constants';

export const MonthDays = ({ value, setValue }: CronFieldsProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isRadio, setIsRadio] = useState('isEveryDayOfMonth');
  const [everyDay, setEveryDay] = useState<Item | null>(optionsEveryDays[0]);
  const [dayStart, setDayStart] = useState<Item | null>(optionsDayStart[0]);
  const [fromDayOfMonth, setFromDayOfMonth] = useState<Item | null>(optionsDayStart[0]);
  const [nextWeekDay, setNextWeekDay] = useState<Item | null>(optionsNextWeekDay[0]);

  useEffect(() => {
    switch (isRadio) {
      case 'isEveryDayOfMonth':
        setValue({ type: isRadio, value: [] });
        break;
      case 'isEveryDefiniteDayOfMonth':
        setValue({ type: isRadio, value: [everyDay?.id!, dayStart?.id!] });
        break;
      case 'isLastDayOfTheMonth':
        setValue({ type: isRadio, value: 'L' });
        break;
      case 'isLastWeekDayOfMonth':
        setValue({ type: isRadio, value: 'LW' });
        break;
      case 'isDefiniteDayOfMonthToEndMonth':
        setValue({ type: isRadio, value: [fromDayOfMonth?.id!] });
        break;
      case 'isNextWeekdayTo':
        setValue({ type: isRadio, value: [nextWeekDay?.id!] });
        break;
      case 'isPeriod':
        break;
    }
  }, [isRadio, everyDay, dayStart, fromDayOfMonth, nextWeekDay, setValue]);

  return (
    <StyledCollapse label='Дни месяца' iconPosition='right' isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
      {/* @ts-ignore */}
      <StyledCard horizontalSpace='m' verticalSpace='m'>
        <StyledCardItem>
          <Radio
            label='Каждый день месяца'
            checked={isRadio === 'isEveryDayOfMonth'}
            onChange={() => setIsRadio('isEveryDayOfMonth')}
          />
        </StyledCardItem>
        <StyledCardItem>
          <Radio
            label='Каждый'
            checked={isRadio === 'isEveryDefiniteDayOfMonth'}
            onChange={() => setIsRadio('isEveryDefiniteDayOfMonth')}
          />
          <Select
            items={optionsEveryDays}
            value={everyDay}
            onChange={({ value }) => setEveryDay(value)}
            style={{ width: 80 }}
          />
          <Typography size='m'>день, начиная с</Typography>
          <Select
            items={optionsDayStart}
            value={dayStart}
            onChange={({ value }) => setDayStart(value)}
            style={{ width: 80 }}
          />
        </StyledCardItem>
        <StyledCardItem column start>
          <Radio
            label='Каждый конкретный день месяца/Период'
            checked={isRadio === 'isPeriod'}
            onChange={() => setIsRadio('isPeriod')}
            style={{ marginBottom: '8px' }}
          />
          {/* <Select placeholder='Выберите' items={minutes} value={value} onChange={({ value }) => setValue(value)} /> */}
        </StyledCardItem>
        <StyledCardItem>
          <Radio
            label='В последний день месяца'
            checked={isRadio === 'isLastDayOfTheMonth'}
            onChange={() => setIsRadio('isLastDayOfTheMonth')}
          />
        </StyledCardItem>
        <StyledCardItem>
          <Radio
            label='В последний будний день месяца'
            checked={isRadio === 'isLastWeekDayOfMonth'}
            onChange={() => setIsRadio('isLastWeekDayOfMonth')}
          />
        </StyledCardItem>
        <StyledCardItem>
          <Radio
            label='С'
            checked={isRadio === 'isDefiniteDayOfMonthToEndMonth'}
            onChange={() => setIsRadio('isDefiniteDayOfMonthToEndMonth')}
          />
          <Select
            items={optionsDayStart}
            value={fromDayOfMonth}
            onChange={({ value }) => setFromDayOfMonth(value)}
            style={{ width: 80 }}
          />
          <Typography size='m'>дня месяца до конца месяца</Typography>
        </StyledCardItem>
        <StyledCardItem>
          <Radio
            label='Ближайший будний день к'
            checked={isRadio === 'isNextWeekdayTo'}
            onChange={() => setIsRadio('isNextWeekdayTo')}
          />
          <Select
            items={optionsNextWeekDay}
            value={nextWeekDay}
            onChange={({ value }) => setNextWeekDay(value)}
            style={{ width: 80 }}
          />
          <Typography size='m'>дню месяца</Typography>
        </StyledCardItem>
      </StyledCard>
    </StyledCollapse>
  );
};

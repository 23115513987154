import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'nx-design/Button';
import { ReactComponent as AppLogo } from '../../assets/logo.svg';
import { ROUTES } from '../../constants/constants';

import {
  StyledMenu,
  StyledSidebar,
  StyledSidebarHeader,
  StyledSubMenu,
  StyledSubMenuTitle,
  StyledMenuItem,
} from './styles';

import {
  IconCloseMenuD,
  IconGrid,
  IconDataset,
  IconCode,
  IconCloudDownload,
  IconOpenMenu,
  IconBell,
  IconChevronUp,
} from '../../assets/Assets';

export const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();

  return (
    <StyledSidebar collapsed={isOpen}>
      <StyledSidebarHeader>
        {isOpen && <AppLogo />}
        <Button
          label='Скрыть'
          view='clear'
          iconLeft={isOpen ? <IconCloseMenuD size='xs' /> : <IconOpenMenu size='xs' />}
          onClick={() => setIsOpen(!isOpen)}
          onlyIcon
        />
      </StyledSidebarHeader>

      <StyledMenu mode='inline' collapsed={isOpen} triggerSubMenuAction='click'>
        <StyledMenuItem icon={<IconGrid size='s' />} key='1' onClick={() => navigate(ROUTES.home)}>
          {isOpen ? 'Главная' : ''}
        </StyledMenuItem>

        <StyledSubMenu
          icon={<IconDataset size='s' />}
          key='sub1'
          title={isOpen ? <StyledSubMenuTitle>Потоки обработки данных</StyledSubMenuTitle> : ''}
          onTitleClick={() => setIsOpen(true)}
          collapsed={!isOpen}
          // @ts-ignore
          expandIcon={<IconChevronUp size='xs' />}
        >
          {/* <StyledMenuItem key='2' onClick={() => navigate(ROUTES.executionHistory)}>
            История выполнения
          </StyledMenuItem> */}
          <StyledMenuItem key='3' onClick={() => navigate(ROUTES.settingsSchedule)}>
            Настройка расписания
          </StyledMenuItem>
        </StyledSubMenu>

        <StyledSubMenu
          icon={<IconCode size='s' />}
          key='sub2'
          title={isOpen ? <StyledSubMenuTitle>Логирование</StyledSubMenuTitle> : ''}
          onTitleClick={() => setIsOpen(true)}
          collapsed={!isOpen}
          // @ts-ignore
          expandIcon={<IconChevronUp size='xs' />}
        >
          <StyledSubMenu
            key='sub3'
            title={isOpen ? <StyledSubMenuTitle>ETL процессы</StyledSubMenuTitle> : ''}
            collapsed={!isOpen}
            // @ts-ignore
            expandIcon={<IconChevronUp size='xs' />}
          >
            <StyledMenuItem key='4' onClick={() => navigate(ROUTES.airflow)}>
              Airflow
            </StyledMenuItem>
            <StyledMenuItem key='5' onClick={() => navigate(ROUTES.sparkHistoryServer)}>
              Spark history server
            </StyledMenuItem>
            <StyledMenuItem key='6' onClick={() => navigate(ROUTES.livy)}>
              Livy
            </StyledMenuItem>
          </StyledSubMenu>
          {/* <StyledMenuItem key='7' onClick={() => navigate(ROUTES.securityAudit)}>
            Аудит безопасности
          </StyledMenuItem> */}
        </StyledSubMenu>

        <StyledSubMenu
          icon={<IconCloudDownload size='s' />}
          key='sub4'
          title={isOpen ? <StyledSubMenuTitle>Среда исполнения</StyledSubMenuTitle> : ''}
          onTitleClick={() => setIsOpen(true)}
          collapsed={!isOpen}
          // @ts-ignore
          expandIcon={<IconChevronUp size='xs' />}
        >
          <StyledMenuItem key='8' onClick={() => navigate(ROUTES.logging)}>
            Логирование
          </StyledMenuItem>
          <StyledMenuItem key='9' onClick={() => navigate(ROUTES.configuringEnvironment)}>
            Настройка
          </StyledMenuItem>
        </StyledSubMenu>

        <StyledMenuItem icon={<IconBell size='s' />} key='10' onClick={() => navigate(ROUTES.notificationSettings)}>
          {isOpen ? 'Метрики' : ''}
        </StyledMenuItem>
      </StyledMenu>
    </StyledSidebar>
  );
};

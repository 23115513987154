import React from 'react';

export type LoginState = {
  username: string;
  password: string;
};

export type DefaultItem = {
  label: string;
  icon?: React.FC;
  href?: string;
};

export type AuthResponse = {
  token: string;
  type: string;
  id: number;
  username: string;
  roles: [];
};

export type DagsResponse = {
  dagId: string;
  state: string;
  startDate: string;
  endDate: string;
  executionTime: number;
};

export type CounterState = {
  running: number;
  success: number;
  failed: number;
  wait: number;
  queued: number;
};

export type Counter = keyof CounterState;

export type DagsInfoResponse = {
  dagStatusAnswer: {
    countDag: number;
    countRunningDag: number;
    countSuccessDag: number;
    countFailedDag: number;
    countWaitDag: number;
    countQueuedDag: number;
    dags: DagsResponse[];
  };
};

export enum DagsStateEnum {
  // eslint-disable-next-line no-unused-vars
  SUCCESS = 'success',
  // eslint-disable-next-line no-unused-vars
  RUNNING = 'running',
  // eslint-disable-next-line no-unused-vars
  FAILED = 'failed',
  // eslint-disable-next-line no-unused-vars
  WAITING = 'waiting',
  // eslint-disable-next-line no-unused-vars
  QUEUED = 'queued',
}

export type Dags = Omit<DagsResponse, 'dagId' | 'executionTime'> & { id: string; executionTime: string };

export type CounterItems = {
  label: string;
  checked: boolean;
  value: Counter;
  color: string;
  backgroundColor: string;
};

export type WorkflowDeploymentResponse = {
  workflowDeployment: WorkflowDeployment[];
};

export type WorkflowsResponse = {
  workflows: Workflow[];
};

export type Workflow = {
  name: string;
  e_id: number;
  _type_: string;
};

export type AirflowsResponse = {
  airflows: Airflow[];
};

export type Airflow = {
  name: string;
  e_id: number;
  _type_: string;
};

export type WorkflowDeployment = {
  e_id: number;
  name: string;
  dagStartDate: string;
  dagScheduleInterval: string;
  airflow: Airflow;
  start: Workflow;
  createDateTime: string;
  createUser: string;
  changeDateTime: string;
  changeUser: string;
  _type_: string;
};

export type WorkflowDeploymentFormValues = {
  name: string;
  dagStartDate: Date | null;
  dagScheduleInterval: string;
  airflow: Item | null;
  start: Item | null;
  createDateTime?: string;
  createUser?: string;
  changeDateTime?: string;
  changeUser?: string;
  e_id?: number;
};

export type Item = {
  label: string;
  id: number;
};

export type DefaultValues = {
  type: string;
  value: number[] | string;
};

export type CronFieldsProps = {
  value: DefaultValues;
  setValue: (value: DefaultValues) => void;
};

export type EnvironmentComponent = {
  componentName: string;
  namespace: string;
  createDate: string;
  createUser: string;
};

export type AllEnvironmentComponentsResponse = {
  kubernetesComponents: KubernetesComponent[];
};

export type ConfigFileResponse = {
  configName: string;
  componentName: string;
  namespace: string;
  limit: {
    cpu: string;
    memory: string;
  };
  request: {
    cpu: string;
    memory: string;
  };
  replicas: number;
  createDate: string;
  createUser: string;
};

export type KubernetesComponent = {
  componentName: string;
  createDate: string;
  createUser: string;
  namespace: string;
};

export type KubernetesComponentListsResponse = {
  configurationFilenameList: string[];
  pods: string[];
};

export type ContainersOnPodResponse = {
  podContainers: PodContainers[];
  podName: string;
  status: {
    running: number;
    waiting: number;
    failed: number;
    success: number;
    count: number;
  };
};

export type PodContainers = {
  containerName: string;
  valueCPU: {
    volume: string;
    time: string;
  }[];
  valueMemory: {
    volume: string;
    time: string;
  }[];
};

export type PodContainersValues = {
  label: string;
  values: {
    volume: string;
    time: string;
  }[];
};

import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { WorkflowDeploymentState } from '../types';
import { WorkflowDeployment } from '../../types/types';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: WorkflowDeploymentState = {
  workflowDeployments: [],
  isEditingWfDeployment: false,
};

const workflowDeploymentsSlice = createSlice({
  name: 'workflowDeployments',
  initialState,
  reducers: {
    setEditing: (state, { payload }: PayloadAction<boolean>) => {
      state.isEditingWfDeployment = payload;
    },
    setWorkflowDeployments: (state, { payload }: PayloadAction<WorkflowDeployment[]>) => {
      state.workflowDeployments = payload;
    },
    createWorkflowDeployments: (state, { payload }: PayloadAction<WorkflowDeployment>) => {
      state.workflowDeployments = [...state.workflowDeployments, payload];
    },
    updateWorkflowDeployments: (state, { payload }: PayloadAction<WorkflowDeployment>) => {
      state.workflowDeployments = state.workflowDeployments.map((item) =>
        item.e_id === payload.e_id ? { ...payload } : item,
      );
    },
    deleteWorkflowDeployments: (state, { payload }: PayloadAction<number>) => {
      state.workflowDeployments = state.workflowDeployments.filter((item) => item.e_id !== payload);
    },
  },
});

export const workflowDeploymentsReducer = workflowDeploymentsSlice.reducer;
export const workflowDeploymentsSelector = (state: RootState) => state.workflowDeployments;
export const {
  setEditing,
  setWorkflowDeployments,
  deleteWorkflowDeployments,
  createWorkflowDeployments,
  updateWorkflowDeployments,
} = workflowDeploymentsSlice.actions;

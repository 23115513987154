import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/named
import { LeftSide, Table, TableColumn } from 'nx-design/Table';
import { HeaderSideProps } from 'nx-design/__internal__/src/components/Table/headerMenu';
// eslint-disable-next-line import/named
import { ItemRightSide, RightSide } from 'nx-design/__internal__/src/components/Table/RightSide/RightSide';
import { Loader } from '../../components/Loader/Loader';
import { IconArrowForward } from '../../assets/Assets';
import { StyledTableSettings } from './style';
import { Button } from 'nx-design/Button';
import { basicColumns, getFilters } from '../../helpers/configuringEnvironmentHelpers';
import { kubernetesComponentsSelector, setKubernetesComponents } from '../../redux/slices/environmentComponentsSlice';
import { getAllComponents } from '../../api/api';

export const ConfiguringEnvironment = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLineVisible, setIsLineVisible] = useState(false);

  const { kubernetesComponents } = useSelector(kubernetesComponentsSelector);
  const filters = getFilters(kubernetesComponents);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const columns: TableColumn<any>[] = [
      ...basicColumns,
      {
        title: 'Действия',
        accessor: 'action',
        align: 'center',
        renderCell: ({ componentName }) => (
          <Button
            view='clear'
            size='xs'
            iconLeft={<IconArrowForward size='xs' />}
            onlyIcon
            onClick={() => navigate(`/configuring-environment/${componentName}`)}
            style={{ marginRight: 4 }}
          />
        ),
      },
    ];

    setCopyColumns(columns);
  }, []);

  const [copyColumns, setCopyColumns] = useState<TableColumn<any>[]>(basicColumns);

  const rightSide: HeaderSideProps = {
    name: RightSide,
    props: {
      nameApplyHiddenColumn: 'Закрыть',
      nameButtonColumnSettings: 'Настроить таблицу',
      overrideHiddenColumns: (newCheckboxGroupValue: ItemRightSide[] | null) => {
        overrideHiddenColumns(newCheckboxGroupValue);
      },
    },
  };

  const leftSide: HeaderSideProps = {
    name: LeftSide,
    props: {
      nameButtonAddColumn: 'Создать',
      nameButtonRefresh: 'Обновить',
      buttonAddType: 'button',
      onClickButtonRefresh: () => updateData(),
    },
  };

  const overrideHiddenColumns = (newCheckboxGroupValue: ItemRightSide[] | null) => {
    const copyColumnsTest = copyColumns.map((column) => {
      const newColumn = { ...column };

      const update: ItemRightSide | undefined =
        newCheckboxGroupValue === null
          ? undefined
          : newCheckboxGroupValue.find((v: ItemRightSide) => newColumn.title === v.title);

      if (update === undefined) {
        if (newColumn.hidden !== undefined) {
          newColumn.hidden = !column.hidden;
        } else if (newColumn.hidden === undefined) {
          newColumn.hidden = true;
        }
        if (newColumn.title === 'id') {
          newColumn.hidden = true;
        }
      }

      return newColumn;
    });

    setCopyColumns(copyColumnsTest);
  };

  const updateData = async () => {
    try {
      setIsLineVisible(true);
      const {
        data: { kubernetesComponents },
      } = await getAllComponents();

      dispatch(setKubernetesComponents(kubernetesComponents));
      setIsLoading(false);
      setIsLineVisible(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    updateData();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <StyledTableSettings>
      <Table
        columns={copyColumns}
        rows={kubernetesComponents}
        filters={filters}
        rightSide={rightSide}
        leftSide={leftSide}
        nameResetAllFilters='Сброcить все фильтры'
        size='m'
        stopIsProgressLineVisible={isLineVisible}
        separateRows
        withHeaderMenu
        stickyHeader
      />
    </StyledTableSettings>
  );
};

import styled from 'styled-components';
import { Typography } from 'nx-design/Typography';

export const StyledFieldWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  margin-bottom: var(--space-m);
  > div {
    flex-basis: 50%;
  }
`;

export const StyledLabel = styled(Typography)`
  color: var(--color-base-base-grey-7);
  margin-bottom: var(--space-2xs);
`;

export const StyledWrapper = styled.div`
  padding: 16px 24px 0 24px;
`;

import React from 'react';
import { Provider } from 'react-redux';
import { RoutesApp } from './routes/RoutesApp';
import { presetDatagram, Theme } from 'nx-design/Theme';
import { store } from './redux/store';
import { Notifications } from './components/Notifications/Notifications';

const App = () => {
  return (
    <Provider store={store}>
      <Theme preset={presetDatagram}>
        <RoutesApp />
        <Notifications />
      </Theme>
    </Provider>
  );
};

export default App;

import React, { useEffect, useReducer } from 'react';
// eslint-disable-next-line import/named
import { Item, reducer } from 'nx-design/Notification';
import { IconCheckC, IconWarning } from '../../assets/Assets';
import { useDispatch, useSelector } from 'react-redux';
import { deleteNotification, notificationsSelector } from '../../redux/slices/notificationsSlice';
import { StyledNotification } from './style';

const notificationsItems: Item[] = [
  {
    key: 'error',
    title: 'Ошибка',
    status: 'error',
    icon: <IconWarning size='m' />,
    autoClose: true,
    withCloseButton: true,
  },
  {
    key: 'success',
    title: 'Успешно',
    status: 'success',
    icon: <IconCheckC size='m' />,
    autoClose: true,
    withCloseButton: true,
  },
];

export const Notifications = () => {
  const [items, dispatchItems] = useReducer(reducer, []);
  const { key, code, message } = useSelector(notificationsSelector);

  const dispatch = useDispatch();

  const generateNotification = (key: string) => {
    const item = notificationsItems.find((item) => item.status === key);
    const notificationBody =
      code === 503 ? 'Сервис временно недоступен' : code === 401 ? 'Авторизационный токен просрочился' : message;
    const notificationTitle = code === 200 ? item?.title : `${item?.title} ${code}`;

    if (item) {
      const notification: Item = {
        ...item,
        title: notificationTitle,
        message: notificationBody,
        onClose: () => dispatchItems({ type: 'remove', key: notification.key }),
      };
      dispatchItems({ type: 'add', item: notification });
    }
    dispatch(deleteNotification());
  };

  useEffect(() => {
    generateNotification(key);
  }, [key]);

  return <StyledNotification items={items} style={{ top: 64, right: 12 }} />;
};

import type { AxiosPromise } from 'axios';
import { axiosFetch } from './axios';
import {
  AuthResponse,
  DagsInfoResponse,
  LoginState,
  WorkflowDeploymentResponse,
  WorkflowsResponse,
  AirflowsResponse,
  WorkflowDeployment,
  AllEnvironmentComponentsResponse,
  KubernetesComponentListsResponse,
  ContainersOnPodResponse,
  ConfigFileResponse,
} from '../types/types';
import { isAuthorized } from '../helpers/utils';

export const postAuth = (data: LoginState): AxiosPromise<AuthResponse> =>
  axiosFetch({
    url: '/auth/signin',
    method: 'POST',
    data,
  });

export const getDags = (dateFrom: Date, dateTo: Date): AxiosPromise<DagsInfoResponse> => {
  const token = isAuthorized();
  return axiosFetch({
    url: `/dag/runs?dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}`,
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getWorkflowDeployments = (): AxiosPromise<WorkflowDeploymentResponse> => {
  const token = isAuthorized();
  return axiosFetch({
    url: '/dg/wfd',
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteWorkflowDeployment = (deleteRow: number): AxiosPromise<any> =>
  axiosFetch({
    url: `/dg/wfd?e_id=${deleteRow}`,
    method: 'DELETE',
  });

export const getAirflows = (): AxiosPromise<AirflowsResponse> =>
  axiosFetch({
    url: `/dg/af`,
    method: 'GET',
  });

export const getWorkflows = (): AxiosPromise<WorkflowsResponse> =>
  axiosFetch({
    url: `/dg/wf`,
    method: 'GET',
  });

export const createWorkflowDeployment = (data: any): AxiosPromise<WorkflowDeployment> =>
  axiosFetch({
    url: `/dg/wfd`,
    method: 'POST',
    data,
  });

export const updateWorkflowDeployment = (data: any): AxiosPromise<WorkflowDeployment> =>
  axiosFetch({
    url: `/dg/wfd`,
    method: 'PUT',
    data,
  });

export const getAllComponents = (): AxiosPromise<AllEnvironmentComponentsResponse> => {
  const token = isAuthorized();
  return axiosFetch({
    url: `/component/getComponentList`,
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getKubernetesComponentLists = (
  namespace: string,
  componentName: string,
): AxiosPromise<KubernetesComponentListsResponse> => {
  const token = isAuthorized();
  return axiosFetch({
    url: `/component/getComponent/${namespace}/${componentName}`,
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getConfigFilesByComponentName = (
  componentName: string,
  configFileName: string,
): AxiosPromise<ConfigFileResponse> => {
  const token = isAuthorized();
  return axiosFetch({
    url: `/component/getConfigurationFileDetail/${componentName}/${configFileName}`,
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getContainersOnPod = (
  namespace: string,
  pod: string,
  startCpuDate: string,
  endCpuDate: string,
  startMemoryDate: string,
  endMemoryDate: string,
): AxiosPromise<ContainersOnPodResponse> => {
  const token = isAuthorized();
  return axiosFetch({
    url: `/component/getPodDetail?namespace=${namespace}&pod=${pod}&startCPU=${startCpuDate}&endCPU=${endCpuDate}&startMemory=${startMemoryDate}&endMemory=${endMemoryDate}`,
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const postCheckUser = (username: string, password: string): AxiosPromise<boolean> =>
  axiosFetch({
    url: `/auth/checkUser`,
    method: 'POST',
    data: { username, password },
  });

export const createConfigFile = (
  replicas: number,
  limitMemory: number,
  limitCpu: number,
  requestMemory: number,
  requestCpu: number,
  componentName: string,
): AxiosPromise<string> => {
  const token = isAuthorized();
  return axiosFetch({
    url: `/component/createConfigFile?replicas=${replicas}&limitMemory=${limitMemory}&limitCpu=${limitCpu}&requestMemory=${requestMemory}&requestCpu=${requestCpu}&componentName=${componentName}`,
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
};

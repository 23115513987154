import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from 'nx-design/DatePicker';
import { Button } from 'nx-design/Button';
import { toDate, subDays } from 'date-fns';
import { withTooltip } from 'nx-design/withTooltip';
import { Typography } from 'nx-design/Typography';
import { ROUTES } from '../../constants/constants';
import { IconArrowBackward, IconBlock, IconEdit, IconRefresh, IconSave } from '../../assets/Assets';
import { StyledActionWrapper, StyledDateWrapper, StyledActionBlock } from './style';
import { StyledDivider } from '../../styles/common';
import { kubernetesComponentsSelector } from '../../redux/slices/environmentComponentsSlice';

const ButtonUpdate = withTooltip({ content: 'Обновить' })(Button);
const ButtonExit = withTooltip({ content: 'Выйти' })(Button);
const ButtonEdit = withTooltip({ content: 'Редактировать' })(Button);
const ButtonSave = withTooltip({ content: 'Сохранить' })(Button);
const ButtonBlock = withTooltip({ content: 'Отменить' })(Button);

type ActionHomeProps = {
  viewMode: 'homePage' | 'componentPage';
  onRefresh: (startRange: any, endRange: any) => void;
  onEdit?: () => void;
  onSave?: () => void;
  onCancel?: () => void;
};

export const ActionHome = ({ viewMode, onRefresh, onEdit, onSave, onCancel }: ActionHomeProps) => {
  const [startRange, setStartRange] = useState<Date | null>(null);
  const [endRange, setEndRange] = useState<Date | null>(null);

  const { isEditing } = useSelector(kubernetesComponentsSelector);
  const navigate = useNavigate();

  const updateData = () => {
    if (startRange !== null && endRange !== null) onRefresh(startRange, endRange);
  };

  useEffect(() => {
    setStartRange(subDays(Date.now(), 1));
    setEndRange(toDate(Date.now()));
  }, []);

  useEffect(() => {
    updateData();
  }, [startRange, endRange]);

  return (
    <StyledActionWrapper>
      <StyledActionBlock>
        {viewMode === 'componentPage' && (
          <>
            <ButtonExit
              name='button-refresh'
              view='clear'
              size='m'
              iconLeft={<IconArrowBackward size='xs' />}
              onlyIcon
              onClick={() => navigate(`/configuring-environment`)}
              disabled={isEditing}
            />
            <StyledDivider />
          </>
        )}
        <ButtonUpdate
          name='button-refresh'
          view='clear'
          size='m'
          iconLeft={<IconRefresh size='xs' />}
          onlyIcon
          onClick={updateData}
        />
        <StyledDivider />
        {viewMode === 'componentPage' ? (
          <>
            {isEditing ? (
              <>
                <ButtonSave
                  name='button-refresh'
                  view='clear'
                  size='m'
                  iconLeft={<IconSave size='xs' />}
                  onlyIcon
                  onClick={onSave}
                />
                <ButtonBlock
                  name='button-refresh'
                  view='clear'
                  size='m'
                  iconLeft={<IconBlock size='xs' />}
                  onlyIcon
                  onClick={onCancel}
                />
              </>
            ) : (
              <ButtonEdit
                name='button-refresh'
                view='clear'
                size='m'
                iconLeft={<IconEdit size='xs' />}
                onlyIcon
                onClick={onEdit}
              />
            )}
          </>
        ) : (
          <>
            <StyledDateWrapper>
              <DatePicker
                type='date-time'
                formatMask='MM.dd.yyyy HH:mm'
                value={startRange}
                onChange={({ value }) => setStartRange(value)}
                style={{ marginRight: 3 }}
              />
              <DatePicker
                type='date-time'
                formatMask='MM.dd.yyyy HH:mm'
                value={endRange}
                onChange={({ value }) => setEndRange(value)}
              />
            </StyledDateWrapper>
          </>
        )}
      </StyledActionBlock>
      {viewMode === 'componentPage' && (
        <StyledActionBlock>
          <Typography size='s' cursor='pointer' style={{ color: '#2AA2C9' }} onClick={() => navigate(ROUTES.logging)}>
            Перейти в логирование
          </Typography>
        </StyledActionBlock>
      )}
    </StyledActionWrapper>
  );
};

import styled from 'styled-components';
import { Typography } from 'nx-design/Typography';

export const StyledFlowCounterWrapper = styled.div`
  margin-bottom: 16px;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(23, 29, 69, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
`;

export const StyledFlowCounterHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 24px;
  border-bottom: 1px solid #e6e6e6;
`;

export const StyledTypography = styled(Typography)`
  color: #404040;
  font-weight: 600;
`;

export const StyledFlowCounterContent = styled.div`
  padding: 14px 24px;
`;

export const StyledFlowCounterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const StyledProgressLine = styled.div<{ color: string; backgroundColor: string; opacity: string }>(
  ({ color, backgroundColor, opacity }) => `
  width: 100%;
  > div {
    height: 12px !important;
    border-radius: 8px;
    width: 100%;
    background-color: ${color};
    opacity: ${opacity};
    &::after {
      background-color: ${backgroundColor};
    }
  }
`,
);

export const StyledCounterName = styled(Typography)`
  min-width: 160px;
  color: var(--color-base-base-grey-8);
`;

import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'nx-design/Typography';
// eslint-disable-next-line import/named
import { TextField, TextFieldOnChangeArguments } from 'nx-design/TextField';
import {
  StyledTitle,
  StyledHeader,
  StyledScalingTableWrapper,
  StyledTableContent,
  StyledTableContentItem,
} from './style';
import { kubernetesComponentsSelector } from '../../redux/slices/environmentComponentsSlice';

type ScalingTableProps = {
  data: any;
  mode: string;
  handleChange: (event: TextFieldOnChangeArguments) => void;
};

export const ScalingTable = ({ mode, data, handleChange }: ScalingTableProps) => {
  const { isEditing } = useSelector(kubernetesComponentsSelector);
  const isHorizontalScaling = mode === 'horizontalScaling';

  return (
    <StyledScalingTableWrapper>
      <StyledTitle>
        <Typography size='l' style={{ color: '#404040', fontWeight: 600 }}>
          {isHorizontalScaling ? 'Горизонтальное масштабирование' : 'Вертикальное масштабирование'}
        </Typography>
      </StyledTitle>
      <StyledHeader>
        <Typography size='m' style={{ fontWeight: 600 }}>
          Параметр
        </Typography>
        <Typography size='m' style={{ fontWeight: 600 }}>
          Значение
        </Typography>
      </StyledHeader>
      <StyledTableContent>
        {isHorizontalScaling ? (
          <StyledTableContentItem>
            <Typography size='m'>Кол-во реплик</Typography>
            <TextField
              type='number'
              name='replicas'
              value={String(data.replicas)}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </StyledTableContentItem>
        ) : (
          <>
            <StyledTableContentItem>
              <Typography size='m'>CPU лимит, m</Typography>
              <TextField
                type='number'
                name='limitCpu'
                onChange={handleChange}
                value={String(data.limitCpu)}
                disabled={!isEditing}
              />
            </StyledTableContentItem>
            <StyledTableContentItem>
              <Typography size='m'>CPU потребление, m</Typography>
              <TextField
                type='number'
                name='consumptionCpu'
                onChange={handleChange}
                value={String(data.consumptionCpu)}
                disabled={!isEditing}
              />
            </StyledTableContentItem>
            <StyledTableContentItem>
              <Typography size='m'>Memory лимит</Typography>
              <TextField
                type='number'
                name='limitMemory'
                onChange={handleChange}
                value={String(data.limitMemory)}
                disabled={!isEditing}
              />
            </StyledTableContentItem>
            <StyledTableContentItem>
              <Typography size='m'>Memory потребление</Typography>
              <TextField
                type='number'
                name='consumptionMemory'
                onChange={handleChange}
                value={String(data.consumptionMemory)}
                disabled={!isEditing}
              />
            </StyledTableContentItem>
          </>
        )}
      </StyledTableContent>
    </StyledScalingTableWrapper>
  );
};

import React, { useEffect, useState } from 'react';
import { TextField } from 'nx-design/TextField';
import { StyledWrapper, StyledTextFieldWrapper } from './style';
import { Minutes } from './fields/Minutes';
import { Hours } from './fields/Hours';
import { MonthDays } from './fields/MonthDays';
import { Month } from './fields/Month';
import { WeekDays } from './fields/WeekDays';
import { DefaultValues } from '../../types/types';

type CronEditorProps = {
  cronStringValue: string;
  setCronStringValue: (value: string) => void;
};

export const CronEditor = ({ cronStringValue, setCronStringValue }: CronEditorProps) => {
  const [minutes, setMinutes] = useState<DefaultValues>({ type: 'isEveryMinute', value: [] });
  const [hours, setHours] = useState<DefaultValues>({ type: 'isEveryHour', value: [] });
  const [monthDays, setMonthDays] = useState<DefaultValues>({ type: 'isEveryDayOfMonth', value: [] });
  const [months, setMonths] = useState<DefaultValues>({ type: 'isEveryMonth', value: [] });
  const [weekDays, setWeekDays] = useState<DefaultValues>({ type: 'isEveryDayOfWeek', value: [] });

  useEffect(() => {
    const cronArrayFromString = cronStringValue?.split(' ') || ['*'];
    switch (minutes.type) {
      case 'isEveryMinute':
        cronArrayFromString[0] = '*';
        break;
      case 'isEveryDefiniteMinute':
        cronArrayFromString[0] = `${minutes.value[0]}/${minutes.value[1]}`;
        break;
    }
    setCronStringValue(cronArrayFromString.join(' '));
  }, [minutes]);

  useEffect(() => {
    const cronArrayFromString = cronStringValue?.split(' ') || ['*'];
    switch (hours.type) {
      case 'isEveryHour':
        cronArrayFromString[1] = '*';
        break;
      case 'isEveryDefiniteHour':
        cronArrayFromString[1] = `${hours.value[0]}/${hours.value[1]}`;
    }
    setCronStringValue(cronArrayFromString.join(' '));
  }, [hours]);

  useEffect(() => {
    const cronArrayFromString = cronStringValue?.split(' ') || ['*'];
    switch (monthDays.type) {
      case 'isEveryDayOfMonth':
        cronArrayFromString[2] = '*';
        break;
      case 'isEveryDefiniteDayOfMonth':
        cronArrayFromString[2] = `${monthDays.value[0]}/${monthDays.value[1]}`;
        break;
      case 'isLastDayOfTheMonth':
        cronArrayFromString[2] = 'L';
        break;
      case 'isLastWeekDayOfMonth':
        cronArrayFromString[2] = 'LW';
        break;
      case 'isDefiniteDayOfMonthToEndMonth':
        cronArrayFromString[2] = `L-${monthDays.value[0]}`;
        break;
      case 'isNextWeekdayTo':
        cronArrayFromString[2] = `${monthDays.value[0]}W`;
        break;
    }
    setCronStringValue(cronArrayFromString.join(' '));
  }, [monthDays]);

  useEffect(() => {
    const cronArrayFromString = cronStringValue?.split(' ') || ['*'];
    switch (months.type) {
      case 'isEveryMonth':
        cronArrayFromString[3] = '*';
        break;
      case 'isEveryDefiniteMonth':
        cronArrayFromString[3] = `${months.value[0]}/${months.value[1]}`;
        break;
    }
    setCronStringValue(cronArrayFromString.join(' '));
  }, [months]);

  useEffect(() => {
    const cronArrayFromString = cronStringValue?.split(' ') || ['*'];
    switch (weekDays.type) {
      // case 'isEveryDayOfWeek':
      //   cronArrayFromString[4] = '*';
      //   break;
      case 'isEveryDefiniteDays':
        cronArrayFromString[4] = `${weekDays.value[0]}/${weekDays.value[1]}`;
        break;
      case 'isLastDayOfMonth':
        cronArrayFromString[4] = `${weekDays.value[0]}L`;
        break;
      case 'isDefiniteDaysOfMonth':
        cronArrayFromString[4] = `${weekDays.value[0]}#${weekDays.value[1]}`;
        break;
    }
    setCronStringValue(cronArrayFromString.join(' '));
  }, [weekDays]);

  return (
    <>
      <StyledTextFieldWrapper>
        <TextField
          name='cronExpression'
          type='text'
          placeholder='*****'
          value={cronStringValue}
          width='full'
          required
        />
      </StyledTextFieldWrapper>
      <StyledWrapper>
        <Minutes value={minutes} setValue={setMinutes} />
        <Hours value={hours} setValue={setHours} />
        <MonthDays value={monthDays} setValue={setMonthDays} />
        <Month value={months} setValue={setMonths} />
        <WeekDays value={weekDays} setValue={setWeekDays} />
      </StyledWrapper>
    </>
  );
};

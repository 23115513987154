import React from 'react';
import { useSelector } from 'react-redux';
import { ProgressLine } from 'nx-design/ProgressLine';
import { CounterItems } from '../../types/types';

import {
  StyledFlowCounterHead,
  StyledFlowCounterWrapper,
  StyledTypography,
  StyledFlowCounterContent,
  StyledFlowCounterItem,
  StyledProgressLine,
  StyledCounterName,
} from './style';
import { counterSelector } from '../../redux/slices/counterSlice';

type FlowCounterProps = {
  items: CounterItems[];
};

export const FlowCounter = ({ items }: FlowCounterProps) => {
  const { counterState } = useSelector(counterSelector);
  const amountActive = items?.reduce((acc, item) => (item.checked ? acc + counterState[item.value] : acc + 0), 0);

  return (
    <StyledFlowCounterWrapper>
      <StyledFlowCounterHead>
        {/* @ts-ignore */}
        <StyledTypography size='l'>Счетчик потоков</StyledTypography>
        <StyledTypography size='3xl'>{amountActive}</StyledTypography>
      </StyledFlowCounterHead>
      <StyledFlowCounterContent>
        {items.map((item) => (
          <StyledFlowCounterItem key={item.label}>
            <StyledCounterName size='m'>{item.label}</StyledCounterName>
            <StyledProgressLine
              color={item.color}
              backgroundColor={item.backgroundColor}
              opacity={item.checked ? '1' : '0.2'}
            >
              <ProgressLine value={counterState[item.value]} />
            </StyledProgressLine>
            <StyledTypography style={{ width: 30, textAlign: 'right' }}>{counterState[item.value]}</StyledTypography>
          </StyledFlowCounterItem>
        ))}
      </StyledFlowCounterContent>
    </StyledFlowCounterWrapper>
  );
};

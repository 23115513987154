import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'nx-design/Button';
import {
  StyledModalTitle,
  StyledModalText,
  StyledContent,
  StyledButtonWrapper,
  StyledModal,
} from '../../styles/common';
import { IconCheck, IconBlock } from '../../assets/Assets';
import { TextField } from 'nx-design/TextField';
import { postCheckUser } from '../../api/api';
import { setIsEditing } from '../../redux/slices/environmentComponentsSlice';
import { Typography } from 'nx-design/Typography';

type SaveSettingsModalProps = { isOpen: boolean; setIsOpen: (isOpen: boolean) => void };

export const SaveSettingsModal = ({ isOpen, setIsOpen }: SaveSettingsModalProps) => {
  const [password, setPassword] = useState<string | null>(null);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const handleClick = async () => {
    try {
      const { data } = await postCheckUser(JSON.parse(localStorage.getItem('username') || ''), password || '');
      dispatch(setIsEditing(data));
      setIsOpen(false);
    } catch (e) {
      if (e.response.status === 401) {
        setError(true);
      }
    }
  };

  return (
    <StyledModal isOpen={isOpen} hasOverlay onOverlayClick={() => setIsOpen(false)}>
      <StyledContent>
        {/* @ts-ignore */}
        <StyledModalTitle size='l' view='secondary'>
          Режим редактирования
        </StyledModalTitle>
        <StyledModalText size='m' view='primary'>
          Для перехода в режим редактирования необходимо ввести пароль
        </StyledModalText>
        <TextField
          name='password'
          type='password'
          value={password}
          onChange={({ value }) => setPassword(value)}
          withClearButton={true}
        />
        {error && (
          <Typography size='m' view='primary' style={{ marginTop: 16, color: '#ff0000' }}>
            Введен неверный пароль, повторите попытку
          </Typography>
        )}
      </StyledContent>
      <StyledButtonWrapper>
        <Button
          size='m'
          view='primary'
          label='Применить'
          iconLeft={IconCheck}
          onClick={handleClick}
          style={{ marginRight: 16 }}
        />
        <Button size='m' view='secondary' label='Отменить' iconLeft={IconBlock} onClick={() => setIsOpen(false)} />
      </StyledButtonWrapper>
    </StyledModal>
  );
};
